<div class="header">
  <div class="header_container">
    <div class="logo">
      <img src="assets/images/logo.svg" alt="Ponedilok">
    </div>
  </div>
</div>

<div class="container">
  <div class="content">
    <h1>Умови та положення для Консультантів</h1>
    <p><b>Публічна оферта про надання послуг доступу до вебсайту <a href="https://Ponedilok.co" target="_blank">https://Ponedilok.co</a></b></p>
    <p>Договір Публічної Оферти вступив в силу: 27.05.2024</p>
    <p>Документ, положення якого викладені нижче по тексту, є публічною офертою та публічним договором. Згідно із положеннями ст.ст. 633, 641 Цивільного кодексу України умови публічної оферти та публічного договору є однаковими для всіх Консультантів. У відповідності до ч. 2 ст. 642 Цивільного кодексу України, реєстрація на вебсайті <a href="https://Ponedilok.co" target="_blank">https://Ponedilok.co</a> у якості Консультанта є акцептом даної оферти, що прирівнюється до укладення договору на умовах викладених нижче по тексту, а також положення Політики конфіденційності. Політика конфіденційності регулює особливості обробки Платформою персональних даних Користувачів. Ознайомитися із положеннями Політики конфіденційності Компанії можна за посиланням <b><a href="/privacy-policy" target="_blank">https://Ponedilok.co/privacy-policy</a></b>. Політика конфіденційності є невід’ємною частиною цих Умов.</p>
    <p>Дана публічна оферта адресована всім фізичним та юридичним особам, що бажають скористатися послугою і мають технічну можливість одержання послуги.</p>
    <p>“Платформа” або “Ponedilok”, з однієї сторони, керуючись чинним законодавством України пропонує (публічна оферта) фізичній особі та/або юридичній особі (далі – Консультанту), в подальшому разом – Сторони, а кожен окремо – Сторона, укласти публічний договір про надання послуг доступу до вебсайту <a href="https://Ponedilok.co" target="_blank">https://Ponedilok.co</a> (далі – Договір, Публічна Оферта) на наступних умовах:</p>

    <h2>1.  ТЕРМІНИ ТА ВИЗНАЧЕННЯ</h2>
    <p>1.1.         <b>Акаунт</b> – це функціональна частина Сайту, що створюється для Консультанта Платформою і за допомогою якого Замовник має можливість отримати Послуги. Реєструючись в Сервісі, Консультант підтверджуєте, що він є дієздатною фізичною особою, яка діє від власного імені або на законних підставах від імені юридичної особи. Консультант також підтверджуєте, що вся інформація, надана в ході реєстрації, є достовірною, точною і актуальною. Порядок зберігання та обробки персональних даних фізичних осіб Платформою встановлено в Політиці конфіденційності.</p>
    <p>1.2.         <b>Акцепт</b> – повна й безумовна згода Консультантом на укладення даного Договору на умовах, визначених даним Договором.</p>
    <p>1.3.         <b>Заявка</b> – це добровільна та чітка дія Замовника, що здійснюється за допомогою функцій його Акаунту та надає можливість Замовнику отримати послуги від Консультанта. Цей процес також включає бронювання конкретного часу та дня отримання послуг від Консультанта, забезпечуючи заздалегідь визначену можливість отримання необхідної послуги від Консультанта.</p>
    <p>1.4.         <b>Арбітраж</b> – це процес вирішення Платформою спорів та суперечок між Замовником та Консультантом, шляхом розгляду їх доводів, аргументів, скарг, запитів тощо.</p>
    <p>1.5.         <b>Замовник (Клієнт)</b> – це будь-яка фізична особа, що замовляє консультацію в Консультанта, шляхом використання Сайту Платформи.</p>
    <p>1.6.         <b>Консультант</b> – це будь-яка фізична особа-підприємець або юридична особа, як пройшла реєстрацію на Сайті з метою надання послуг пізнавального характеру (не надає консультацію як таку, що породжує будь-які юридичні наслідки та не несе в собі інформацію дійсного/фактичного та/або дорадчого змісту) Замовнику у сфері здорового образу життя та дотичних галузях, що вказані у відповідному розділі Сайту.</p>
    <p>1.7.         <b>Користувач</b> – це загальна назва для Консультанта та Замовника.</p>
    <p>1.8.         <b>Консультація</b> – це інформаційна послуга, що надається Замовнику виключно з метою отримання пізнавальної інформації. Послуга не має характеру консультації, що може призводити до юридичних наслідків, та не включає в себе дійсну/фактичну та/або дорадчу інформацію, надану Консультантом на підставі заявки Замовника. Ця послуга надається Замовнику за технічної підтримки Сайту і на умовах індивідуального обмеженого доступу.</p>
    <p>1.9.       <b>Згода Консультанта (далі «Згода»)</b> — це добровільне, конкретне, проінформоване та недвозначне волевиявлення, в якому Консультант за допомогою заяви чи явної позитивної дії погоджується з умовами цієї Публічної Оферти.</p>
    <p>1.10.       <b>Персональні дані</b> —  це будь-яка інформація, яка знаходиться в закритому доступі, що дозволяє прямо або опосередковано ідентифікувати Користувача. Наприклад, ім’я, прізвище, номер телефону, IP-адреса.</p>
    <p>1.11.       <b>Статистичні дані</b> — це будь-яка інформація, яка знаходиться у відкритому доступі та прямо чи опосередковано належать Користувачу. Наприклад, дані про адресу Консультанта, назву юридичної особи тощо.</p>
    <p>1.12.       <b>Платформа “Ponedilok” (надалі “Платформа” або “Ponedilok”)</b> – це сервіс, що створений для надання можливості Замовнику обрати Консультанта з метою отримання консультації, а також надання можливості Консультантам здійснювати свою діяльність з метою надання послуг Замовнику.</p>
    <p>1.13.       <b>Вебсайт Платформи “Ponedilok” (надалі “Сайт”)</b> – означає вебсторінку або групу вебсторінок в мережі Інтернет, які розміщені за адресою: <b><a href="https://Ponedilok.co" target="_blank">https://Ponedilok.co</a></b>, за допомогою яких Платформа  надає Послуги Користувачам.</p>
    <p>1.14.       <b>Послуги</b> — це алгоритм дій, що виконуються Платформою для надання Консультанту можливості доступу до Сайту <b><a href="https://Ponedilok.co" target="_blank">https://Ponedilok.co</a></b> для розміщення свого оголошення з метою надання консультацій Замовнику.</p>
    <p>1.15.       <b>Третя особа</b> – означає фізичну або юридичну особу, державну установу, установу або орган, відмінні від Користувача та Платформи.</p>

    <h2>2.  ПРЕДМЕТ ДОГОВОРУ</h2>
    <p>2.1.         На підставі та умовах визначених даним Договором, Платформа надає Консультанту, а Консультант приймає послуги з доступу до Сайту <b><a href="https://Ponedilok.co" target="_blank">https://Ponedilok.co</a></b> лише після здійснення Консультантом реєстрації на сайті <b><a href="https://Ponedilok.co" target="_blank">https://Ponedilok.co</a></b> та створення акаунту.</p>
    <p>2.2.         Всі зміни й доповнення до даного Договору опубліковуються на Сайті.</p>
    <p>2.3.         Всі умови даного Договору є обов’язковими для Сторін. Перед початком користування Послугою Консультант зобов’язаний ознайомитися з умовами даного Договору. Якщо Консультант не згодний з умовами даного Договору, він не вправі користуватися послугами.</p>
    <p>2.4.         У разі незгоди Консультанта зі змінами, внесеними Платформою у даний Договір або з новими тарифами на Послуги Консультант повинен припинити користування сервісом.</p>
    <p>2.5		Доступ до платформи для Консультанта надається на платній основі після завершення випробувального періоду довжиною в три місяці. В разі якщо консультант не сплачує підписку, доступу до платформи не буде.</p>

    <h2>3.  ЗГОДА КОНСУЛЬТАНТА</h2>
    <p>3.1.         Реєстрація на вебсайті <b><a href="https://Ponedilok.co" target="_blank">https://Ponedilok.co</a></b> у якості Консультанта є акцептом даної оферти, що прирівнюється до укладення договору на умовах викладених нижче по тексту, а також положення Політики конфіденційності.</p>
    <p>3.2.         Договір має юридичну силу відповідно до ст.ст. 633, 641, 642 Цивільного кодексу України, інших положень чинного законодавства України, і є рівносильним Договору, підписаному між Консультантом та Платформою.</p>
    <p>3.3.         Якщо Консультант не згодний з умовами даного Договору, він не вправі користуватися послугами.</p>
    <p>3.4.         Будь-яка з наступних дій означають Згоду з умовами цієї Публічної Оферти:</p>
    <p>3.4.1.         реєстрація/авторизація на Сайті; та/або</p>
    <p>3.4.2.         отримання Послуг;</p>
    <p>3.4.3.         надання консультації Замовнику.</p>
    <p>3.5.         Платформа залишає за собою право отримати Згоду Консультанта через форму, що випливає. Випливаюча форма може містити форму Згоди у вигляді checkbox (прапорця).</p>
    <p>3.6.         Акцептом цієї Публічної Оферти, Консультант автоматично підтверджує факт ознайомлення та погоджується з повним та безумовним прийняттям її положень та умов.</p>
    <p>3.7.         Погоджуючись з умовами Публічної Оферти, Консультант надає свою пряму Згоду з:</p>
    <p>3.7.1.         умовами отримання Послуг, що передбачені цією  Публічною Офертою та умовами викладеними на Сайті;</p>
    <p>3.7.2.         реєстрацією на Сайті;</p>
    <p>3.7.3		умовами оплати за використання платформи;</p>
    <p>3.7.4.         умовами надання послуг Замовнику;</p>
    <p>3.7.5.         умовами отримання оплати за надання послуг Замовнику;</p>
    <p>3.7.6.         дотриманням умов цієї  Публічної Оферти.</p>

    <h2>4.  РЕЄСТРАЦІЯ/АВТОРИЗАЦІЯ</h2>
    <p>4.1.         Для отримання Послуг, Консультанту необхідно здійснити реєстрацію на Сайті у якості Консультанта. Для реєстрації на Сайті у якості Консультанта, Замовник має скористатись відповідним розділом Сайту та вказати Персональні дані, що вказані у відповідному розділі Сайту.</p>
    <p>4.2.         Для реєстрації на Сайті Консультант має здійснити наступні дії:</p>
    <p>4.2.1.         вказати: повне ім’я (ПІБ); дату народження; контактний номер телефону; Email; стать;</p>
    <p>4.2.2.         обрати спеціалізацію, відповідно до параметрів вказаних на Сайті;</p>
    <p>4.2.3.         завантажити диплом/сертифікат (за бажанням);</p>
    <p>4.2.4.         обрати експертизу, відповідно до параметрів вказаних на Сайті;</p>
    <p>4.2.5.         вказати мову надання послуг Замовнику;</p>
    <p>4.2.6.         вказати мову проведення сеансу, відповідно до параметрів вказаних на Сайті;</p>
    <p>4.2.7.         вказати досвід роботи, відповідно до параметрів вказаних на Сайті;</p>
    <p>4.2.8.         вказати свій сайт (якщо він є в наявності), відповідно до параметрів вказаних на Сайті;.</p>
    <p>4.2.9.       погодитись з “Правилами для Консультантів”, що розміщенні на Сайті;</p>
    <p>4.2.10.       описати себе та свій досвід;</p>
    <p>4.2.11.       написати вступний текст.</p>
    <p><b><u>4.3.         Після реєстрації на Сайті, Консультантові надається право безкоштовного користування платформою протягом випробувального періоду тривалістю 3 місяці. Після цього терміну послуги платформи стають платними (Місячна або річна підписка).</u></b></p>
    <p>4.4.         Для кожного Консультанта, доступна реєстрація одного Акаунту. Якщо Консультант зареєструє другий Акаунт, то він буде заблокований Платформою.</p>
    <p>4.5.         Створюючи Акаунт, Консультант погоджується з тим, що:</p>
    <p>4.5.1.         негайно повідомить Платформу, якщо йому стане відомо про будь-яке несанкціоноване використання його Акаунту або будь-якого іншого порушення правил безпеки;</p>
    <p>4.5.2.         буде суворо дотримуватись усіх механізмів та процедур Платформи, що стосуються безпеки та автентифікації Акаунту.</p>
    <p>4.6.         Авторизація на Сайті здійснюється Консультантом способом зазначеним Платформою у відповідному розділі Сайту. Для здійснення авторизації Консультант зобов’язується вказати Персональні дані, що вказані Платформою.</p>
    <p>4.7.         Консультант зобов’язується не розголошувати дані необхідні для доступу до свого Акаунту та несе одноосібну відповідальність у разі отримання доступу до його Акаунту Третьою особою. Якщо Консультант втратив доступ до свого Акаунту, Платформа може відновити доступ до такого Акаунту, якщо Консультант надішле запит Платформі. Такий запит повинен містити: ідентифікаційні дані Консультанта, дані про Акаунт, документ, що підтверджує особу Консультанта, опис проблеми, що виникла.</p>
    <p>4.8.         Для видалення облікового запису, Консультант повинен надіслати запит Платформі. Такий запит має містити наступну інформацію: ідентифікаційні дані Консультанта, дані про Акаунт, документ, що підтверджує особу Консультанта, підтвердження видалення Акаунту. В разі отримання такого запиту, Акаунт та вся інформація в ньому буде видалена назавжди.</p>
    <p>4.9.       Консультант повинен надіслати запит Платформі за наступною електронною адресою: <a href="mailto:support@ponedilok.co" target="_blank">support&#64;ponedilok.co</a>.</p>
    <p>4.10.       Платформа розглядає запит Консультанта протягом <b>15 (п’ятнадцяти) робочих днів</b> з моменту його отримання.</p>
    <p>4.11.       Консультант не має право передавати свій Акаунт будь-якій Третій особі жодним чином, оскільки така передача може тягнути за собою порушення інтелектуальних та майнових прав. Консультант може надати доступ до свого Акаунту Третій особі, що буде представляти його інтереси та діяти від його імені. В разі надання доступу Консультантом до свого Акаунту Третій особі, Консультант одноосібно несе відповідальність за дотримання такою особою цієї Публічної Оферти та/або будь-яку втрату Персональних та Статистичних даних та/або порушення інтелектуальних прав.</p>

    <h2>5.  ПОРЯДОК НАДАННЯ ПОСЛУГ</h2>
    <p>5.1.         Платформа надає Консультантам Послуги:</p>
    <p>5.1.1.         можливість розмістити інформацію про себе та свої послуги на Сайті;</p>
    <p>5.1.2.         надати послуги Замовнику онлайн за допомогою використання Сайту;</p>
    <p>5.1.3.         Оплата за послуги Консультанта відбувається поза межами платформи. Платформа не несе відповідальності за розрахунки між Консультантом та Замовником.</p>
    <p><b><u>5.2.         Консультант не має права надавати за допомогою Сайту освітні послуги або здійснювати за допомогою Сайту іншу діяльність, що підлягає ліцензуванню, або супроводжується проведенням підсумкової атестації, присвоєння будь-якої кваліфікації та видачою документа про освіту. Якщо Платформі стане відомо про порушення п. 5.2. Договору Консультантом, то Платформа має право видалити Акаунт Консультанта, а всі кошти його Балансу будуть перераховані на рахунок Платформи в якості відшкодування збитків.</u></b></p>
    <p>5.3.         Консультант надає Користувачеві послуги шляхом публікації послуг/консультації або інформаційного продукту у відповідь на Заявку Замовника на сторінці Сайту з індивідуальним доступом. У разі, якщо наданих Замовником даних недостатньо для надання послуг, на сторінці з індивідуальним доступом Сайту публікується запит на отримання від Замовника інформації, якої бракує. Обсяг відповідей Консультанта не регламентується.</p>
    <p>5.4.         Консультант залишає за собою право залишити без відповіді Заявки Замовника, які зроблені поза рамками Сайту або узгодженої з Замовником тематики надання послуг. У цьому випадку Консультант надсилає Замовнику відповідне повідомлення, після отримання якого Замовник має право надіслати Консультанту скориговану Заявку на отримання нової відповіді, що відповідає заявленій тематиці надання послуг Консультантом.</p>
    <p>5.5.         Послуги вважаються наданими належним чином і в повному обсязі, якщо вони були надані у встановлений строк, що вказаний на Сайті. Суб’єктивні претензії з боку Замовника, такі як “не подобається”, “не згоден” та інші подібні претензії не є доказом неналежного надання послуг Консультантом.</p>
    <p>5.6.         Тематика, розклад, тривалість надання послуг, узгоджуються між Консультантом та Замовником під час перемовин, після направлення Заявки на отримання послуг від Консультанта.</p>
    <p>5.7.         Консультант має право в будь-який момент змінювати дату і час надання своїх послуг, сповістивши про це Замовника за один робочий день до дати надання послуг Консультантом Замовнику, шляхом направлення письмового повідомлення через особисте листування на Сайті із зазначенням причин неможливості надання таких послуг.</p>
    <p>5.8.         Під час надання послуг Замовнику, Консультанту забороняється вчиняти наступні дії:</p>
    <p>5.8.1.         будь-які дії, що порушують законодавство України;</p>
    <p>5.8.2.         будь-які види шахрайства та дії, які вводять Замовника в оману, зокрема, подання неправдивої інформації про свої кваліфікації, досвід чи результати послуг;</p>
    <p>5.8.3.         використання чужих фотографій, відео чи інших матеріалів;</p>
    <p>5.8.4.       обіцянки результатів, які нереально досягти, або гарантії ефектів, що не можуть бути об’єктивно підтверджені;</p>
    <p>5.8.5.       створення ілюзії невідкладної потреби у послугах з метою збагачення;</p>
    <p>5.9.       Консультант не має право надавати послуги Замовнику в наступних обставинах:</p>
    <p>5.9.1.       медичну допомогу;</p>
    <p>5.9.2.       допомогу в інших ситуаціях, що не належать до заявлених Консультантом послуг.</p>
    <p>5.10.       Під час надання послуг Замовнику, Консультант не має право жодним чином публікувати наступний контент:</p>
    <p>5.10.1.       будь-яким чином порушує права Третьої особи, включаючи майнові та/або інтелектуальні права;</p>
    <p>5.10.2.       містить незаконну інформацію;</p>
    <p>5.10.3.       містить неправдиву або неправильну інформацію;</p>
    <p>5.10.4.       містить ознаки порнографічного чи сексуального характеру, ненормативну лексику;</p>
    <p>5.10.5.       принижує честь та гідність Третьої особи, інших Користувачів/Третіх осіб;</p>
    <p>5.10.6.       розпалює міжрасову, міжнаціональну, релігійну, статеву та політичну ненависть;</p>
    <p>5.10.7.       порушує права та/або свободи інших Користувачів/Третіх осіб;</p>
    <p>5.10.8.       містить посилання на вебсайти третіх осіб, які можуть завдати шкоди програмному забезпеченню інших Користувачів/Третіх осіб;</p>
    <p>5.10.9.       містить посилання на сайти, що розповсюджують порнографію, неліцензійне програмне забезпечення та/або медіафайли або розпалюють міжрасову, етнічну, релігійну, сексуальну та політичну ненависть;</p>
    <p>5.10.10.     містить посилання на інформацію, яка є відкритими рекламними майданчиками для мережевого маркетингу, партнерського маркетингу, фінансових пірамід, заробітку вдома та інших видів шахрайства;</p>
    <p>5.10.11.     містить нецензурну лексику;</p>
    <p>5.10.12.     містить жаргонізми у розмірі більше 30 (тридцяти) відсотків утримання;</p>
    <p>5.10.13.     містить флуд;</p>
    <p>5.10.14.     містить рекламу алкогольної та/або тютюнової продукції;</p>
    <p>5.10.15.     містить спам;</p>
    <p>5.10.16.     містить заклики до насильства;</p>
    <p>5.10.17.     містить наклеп;</p>
    <p>5.10.18.     використовує кілька згрупованих розділових знаків;</p>
    <p>5.10.19.     містить посилання на сайти, які є службами знайомств, ескорту тощо;</p>
    <p>5.10.20.     містити ознаки жебракування;</p>
    <p>5.10.21.     містить посилання на вебсайти та/або інформацію про курси особистісного зростання, розвитку кар’єри тощо.</p>
    <p>5.11.       Платформа  залишає за собою право перевіряти зміст листування, консультацій, обміну інформації між Замовниками та Консультантами, та у разі порушення умов Публічної Оферти.</p>

    <h2>6.  ВАРТІСТЬ ПОСЛУГ ТА ПОРЯДОК ОПЛАТИ</h2>
    <p>6.1.         Вартість Послуг за даним Договором визначається відповідно до діючих тарифних планів, які опубліковані на Сайті. Ціни вказуються в національній валюті України.</p>
    <p>6.2.         Всі взаєморозрахунки між Сторонами здійснюється в гривні.</p>
    <p>6.4.         Консультант несе зобов’язання з оплати з банківських комісій, оплати послуг платіжної системи та всіх інших зборів, що виникають з перерахування коштів на користь платформи.</p>

    <h2>7.  ПОВЕРНЕННЯ КОШТІВ</h2>
    <p>7.1		Повернення коштів консультанту НЕ відбувається за будь-яких обставин. У Консультанта є можливість відмінити підписку, що буде означати, що наступний період не буде оплачено і у Консультанта не буде прав користування платформою.</p>

    <h2>8.  ІНТЕЛЕКТУАЛЬНІ ПРАВА</h2>
    <p>8.1.         Усі виключні права інтелектуальної власності на програмний код, дизайн Сайту, а також його складові та їх елементи належать виключно Платформі Ponedilok.</p>
    <p>8.2.         Ponedilok надає Консультанту невиключне право використання Послуг та контенту Сайту.</p>
    <p>8.3.         Користувач надає Ponedilok невиключне право використовувати, копіювати, обробляти та передавати Персональні та Статистичні дані протягом терміну використання Послуг.</p>

    <h2>9.  АРБІТРАЖ</h2>
    <p>9.1.         Для вирішення спорів між Користувачами та розгляду скарг від Замовника/Консультанта, Платформа може проводити Арбітраж.</p>
    <p>9.2.         Для проведення Арбітражу, Користувач має надіслати запит або скаргу Арбітражу на електронну пошту: <a href="mailto:support@ponedilok.co" target="_blank">support&#64;ponedilok.co</a></p>
    <p>9.3.         Під час проведення Арбітражу, Платформа має право запросити від Користувача надання будь-якої додаткової інформації та/або даних, а Користувач зобов’язується надати такі дані в термін вказаний Платформою. Якщо Користувач не надає таких даних, то Платформа має право: або призупинити Арбітраж, або прийняти рішення на даних, що є в наявності, або відмінити Арбітраж.</p>
    <p>9.4.         Термін проведення Арбітражу встановлюється Платформою в односторонньому порядку.</p>
    <p>9.5.         Платформа приймає рішення в межах Арбітражу в односторонньому порядку. Рішення Платформи, прийняте під час Арбітражу, є обов’язковим для виконання Користувачем та не підлягає оскарженню Користувачем.</p>

    <h2>10. ПРАВА ТА ОБОВ’ЯЗКИ СТОРІН</h2>
    <p>10.1.       <b><u>Права Консультанта:</u></b></p>
    <p>10.1.1.       отримати Послуги;</p>
    <p>10.1.2.       надавати послуги Замовнику;</p>
    <p>10.1.3.       отримати оплату від Замовника поза межами платформи;</p>
    <p>10.1.4.       опублікувати інформацію про себе та свою діяльність в своєму Акаунті;</p>
    <p>10.1.5.       звертатися до Арбітражу.</p>
    <p>10.2.       <b><u>Обов’язки Консультанта:</u></b></p>
    <p>10.2.1.       надати Замовнику послуги належної якості у форматі та в строк, визначений між Замовником та Консультантом;</p>
    <p>10.2.2.       під час надання Послуг виявляти повагу до особистості Замовника, оберігати його від усіх форм психологічного насильства, забезпечити емоційне благополуччя Замовника з урахуванням його індивідуальних особливостей;</p>
    <p>10.2.3.       здійснити всі дії для надання послуг Замовнику найвищої якості та задовольнити його потреби по мірі можливості;</p>
    <p>10.2.4.       повідомити Замовника, в разі зміни дати та часу надання послуг;</p>
    <p>10.2.5.       зареєструватись на Сайті;</p>
    <p>10.2.6.       сплачувати послуги сайта після закінчення випробувального періоду в разі наявності бажання використовувати платформу надалі;</p>
    <p>10.2.7.       не публікувати контент, вказаний в пункті 5.11 цього Договору;</p>
    <p>10.2.8.     суворо дотримуватись умов цієї Публічної Оферти.</p>
    <p>10.3.       <b><u>Права Платформи:</u></b></p>
    <p>10.3.1.       надати Консультанту доступ до Сайту на безкоштовній основі під час випробувального терміну та на платній основі після його завершення;</p>
    <p>10.3.2.       перевіряти особу та Персональні/Статистичні дані Консультанта;</p>
    <p>10.3.3.       в односторонньому порядку приймати рішення під час Арбітражу;</p>
    <p>10.3.4.       застосовувати санкції до Консультанта, відповідно до умов цієї Публічної Оферти;</p>
    <p>10.3.5.       запитати будь-яку додаткову інформацію у Консультанта;</p>
    <p>10.3.6.       в односторонньому порядку вносити зміни до цієї Публічної Оферти;</p>
    <p>10.3.7.       в односторонньому порядку змінити умови надання Послуг, включаючи їх вартість.</p>
    <p>10.4.       <b><u>Обов’язки Платформи:</u></b></p>
    <p>10.4.1.       надати Консультанту можливість надати послуги Замовнику та отримати оплату;</p>
    <p>10.4.2.       надати Послуги Консультанту;</p>
    <p>10.4.3.       прийняти рішення під час Арбітражу;</p>
    <p>10.4.4.       суворо дотримуватись умов цієї Публічної Оферти.</p>

    <h2>11. ВІДПОВІДАЛЬНІСТЬ</h2>
    <p>11.1.       <b><u>Ponedilok не представляє інтереси ані Замовника, ані Консультанта і не є стороною взаємовідносин, що здійснюються між Замовником та Консультантом за допомогою Сайту. Саме тому Платформа не несе жодної відповідальності будь-які взаємовідносини між Замовником та Консультантом та не зобов’язується відшкодовувати збитки ні Консультанту ні Замовнику.</u></b></p>
    <p>11.2.       <b><u>Оплата послуг Консультанта здійснюється виключно Замовником та Платформа не несе жодної відповідальності в разі неотримання коштів Консультантом.</u></b></p>
    <p>11.3.       <b><u>Платформа не несе відповідальності:</u></b></p>
    <p>11.3.1.       <b><u>за задоволення Замовника наданими послугами Консультантом;</u></b></p>
    <p>11.3.2.       <b><u>за отримання оплати Консультантом;</u></b></p>
    <p>11.3.3.       <b><u>за безперебійний доступ Консультанта до Сайту;</u></b></p>
    <p>11.3.4.       <b><u>за відгуки Замовника;</u></b></p>
    <p>11.3.5.       <b><u>за завдання збитків честі та гідності Консультанту;</u></b></p>
    <p>11.3.6.       <b><u>за настання будь-яких збитків у Консультанта;</u></b></p>
    <p>11.3.7.       <b><u>за шкоду ділової репутації Консультанту.</u></b></p>
    <p>11.4.       Перелік вимог, зазначених у пунктах 5.9., 5.10., 5.11. цієї Публічної Оферти, не є вичерпним, та Платформа має право на свій розсуд прийняти рішення про порушення Консультантом вимог викладеним в цих пунктах.</p>
    <p>11.5.       За порушення умов цієї Публічної Оферти, Платформа може застосовувати наступні санкції:</p>
    <p>11.5.1.       блокування Акаунту;</p>
    <p>11.5.2.       блокування доступ до надання Послуг як повністю, так і частково;</p>
    <p>11.5.3.       видалення Акаунту та ненадання Послуг.</p>
    <p>11.6.       Будь-яка думка, послуга, консультація порада, рекомендація, відповідь або пропозиція, що надаються Консультантом Замовнику, не може розглядатися або прийматися ними як юридична, медична, фінансова, інвестиційна інформація або будь-яка інша послуга, яка підлягає ліцензуванню, або підпадає під певні кваліфікаційні вимоги та/або сертифікацію. Консультант не має права надавати послуги, вказані в цьому пункті, якщо Консультант надає такі послуги, то Платформа має право застосувати санкції до такого Консультанта, відповідно до умов цієї Публічної Оферти.</p>
    <p>11.7.       Консультант має право на створення лише одного Акаунту, в разі створення другого Акаунта, Платформа має право видалити другий Акаунт такого Консультанта, а кошти такого Консультанта будуть перераховані на рахунок Платформи, в якості відшкодування збитків.</p>
    <p>11.8.     Консультант визнає, підтверджує  та погоджується  з тим, що послуги які він надає Замовнику не призначені для використання в надзвичайних ситуаціях, в медичних і психологічних цілях, для терапії, лікування або в інших цілях, крім пізнавального характеру.</p>
    <p>11.9.     Ponedilok залишає за собою право, але не обов’язок:</p>
    <p>11.9.1.     відстежувати порушення цієї Публічної Оферти;</p>
    <p>11.9.2.     вживати відповідних юридичних дій проти будь-кого, хто на свій розсуд порушує закон або ця Публічна Оферта, включаючи, крім іншого, повідомлення про такого Користувача до правоохоронних органів;</p>
    <p>11.9.3.     на наш власний розсуд і без обмежень, повідомлення або відповідальності видалити на Сайті або іншим чином відключити всі файли та контент, які мають надмірний розмір або іншим чином навантажують наші системи;</p>
    <p>11.9.4.     адмініструвати Сайт таким чином, щоб захищати наші права та власність та сприяти належному функціонуванню Сайту.</p>
    <p>11.10.     <b><u>Консультант несе одноосібну відповідальність за надання послуг Замовнику, включаючи відшкодування збитків та заподіяння шкоди фізичному/психічному здоров’ю Замовника.</u></b></p>
    <p>11.11.     Тією мірою, якою це дозволено законом, ми надаємо матеріали та Послуги на умовах «як є». Це означає, що ми не даємо жодних гарантій, включаючи, крім того, гарантії придатності Послуг для певної мети.</p>
    <p>11.12.     Ponedilok, афілійовані особи та агенти не несуть відповідальності за будь-які затримки або збої в Послугах, спричинені подіями які не контролюються Платформою: стихійні лиха, збої в Інтернеті, збої обладнання, відключення електроенергії, страйки, трудові спори, заворушення, повстання, громадянські заворушення, дефіцит, пожежі, повені, шторми, вибухи, стихійні лиха, війни, бойові дії, епідемії, пандемії, дії уряду, судові розпорядження, невиконання Третіми особами або збої в транспорті чи бізнесі.</p>
    <p>11.13.     <b><u>ПЛАТФОРМА НЕ НЕСЕ ВІДПОВІДАЛЬНОСТІ ЗА НАСЛІДКИ, ВИКЛИКАНІ ДІЯМИ ХАКЕРІВ, ЗЛОЧИННОЇ МОДИФІКАЦІЄЮ ПРОГРАМНОГО ЗАБЕЗПЕЧЕННЯ, А ТАКОЖ ІНШИМИ ВИДАМИ САНКЦІЙ, АБО ВАШОГО АКАУНТУ.</u></b></p>

    <h2>12. ЗАКОНОДАВСТВО І ВИРІШЕННЯ СПОРІВ</h2>
    <p>12.1.       Усі відносини між Консультантом та Платформою, що виникають у зв’язку з виконанням умов цих Послуг, регулюються законодавством України.</p>
    <p>12.2.       Будь-який спір, що виникає у зв’язку з виконанням Публічної Оферти, включаючи будь-які питання, що стосуються її існування, дійсності або припинення, має бути передано та остаточно вирішено судом України, відповідно до законодавства України.</p>

    <h2>13. ТЕРМІН ДІЇ</h2>
    <p>13.1.       Публічна Оферта діє протягом періоду використання Послуг Консультантом. У разі припинення використання Послуг та припинення відносин між сторонами умови Публічної Оферти перестають діяти.</p>
    <p>13.2.       Платформа має право в будь-який час внести зміни в умови Публічної Оферти та/або відкликати Публічну Оферту в будь-який момент на свій розсуд. У разі внесення Платформою змін в Публічну Оферту, такі зміни вступають в силу з моменту розміщення зміненого тексту Оферти на Сайті, якщо інший термін вступу змін в силу не визначений безпосередньо в тексті зміненої Публічної Оферти.</p>

    <h2>14. ФОРС-МАЖОР</h2>
    <p>14.1.       Сторони звільняються від відповідальності за повне або часткове невиконання зобов’язань, якщо це невиконання викликане обставинами, що не залежать від волі Сторін, а саме: військовими діями, діями банківських установ щодо блокування банківських рахунків, стихійним лихом, техногенними та іншими аваріями, страйками, локаутами, актами органів влади або управління тощо, що унеможливлює виконання умов цього Договору (далі Форс-мажор).</p>
    <p>14.2.       Сторони звільняються від відповідальності за повне або часткове невиконання зобов’язань, якщо це невиконання викликане обставинами, що не залежать від волі Сторін, а саме: військовими діями, стихійним лихом, техногенними та іншими аваріями, страйками, локаутами, актами органів влади або управління тощо, що унеможливлює виконання умов цього Договору (далі Форс-мажор).</p>
    <p>14.3.       Сторона, для якої наступили вказані обставини, зобов’язується негайно повідомити іншу Сторону та надати відповідні підтверджувальні документи.</p>
    <p>14.4.       З моменту отримання такого повідомлення іншою стороною виконання умов даного Договору припиняється на весь період дії Форс-мажору.</p>
    <p>14.5.       У разі дії Форс-мажорних обставин більше 3 (трьох) місяців, кожна Сторона має право ініціювати припинення Договору.</p>
  </div>
</div>
