<div class="search_wrap">
  <mat-form-field appearance="outline">
    <span class="icon-search" matPrefix></span>

    <input
      matInput
      [placeholder]="'Поиск' | translate"
      [formControl]="name"
    />

    <mat-error>
      <app-validator-message [field]="name">
      </app-validator-message>
    </mat-error>
  </mat-form-field>
</div>
