import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';

import {SystemRulesComponent} from './system-rules.component';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {ModalHeaderModule} from '../modals/modal-header/modal-header.module';
import {
  ModalConsultationRulesComponent
} from '@shared-modules/system-rules/modal-consultation-rules/modal-consultation-rules.component';


@NgModule({
  declarations: [
    SystemRulesComponent,
    ModalConsultationRulesComponent
  ],
  exports: [
    SystemRulesComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatDialogModule,
    ModalHeaderModule
  ]
})
export class SystemRulesModule {
}
