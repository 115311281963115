import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import localeUa from '@angular/common/locales/uk';
import localeEn from '@angular/common/locales/en';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {provideCharts, withDefaultRegisterables} from 'ng2-charts';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig
} from '@abacritt/angularx-social-login';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {RecaptchaInterceptor} from '@core/interceptors/recaptcha.interceptor';
import {CalendarDateFormatter, CalendarModule, DateAdapter} from 'angular-calendar';
import {CalendarFormatterClass} from '@core/classes/calendar-formatter.class';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PatientsInviteModule} from './dashboard/patients-invite/patients-invite.module';
import {MyNetworkErrorComponent} from './chat-errors/my-network-error/my-network-error.component';
import {
  PatientNetworkErrorComponent
} from './chat-errors/patient-network-error/patient-network-error.component';
import {AnamnesisModule} from '@shared-modules/anamnesis/anamnesis.module';
import {TokenInterceptor} from '@core/interceptors/requests.interceptor';
import {TimezoneInterceptor} from '@core/interceptors/timezine.interceptor';
import {SafeImageModule} from '@shared-modules/safe-image/safe-image.module';
import {AppLayoutModule} from '@shared-modules/layouts/app-layout/app-layout.module';
import {SharedModule} from './shared/shared.module';
import {ModalConfirmModule} from '@shared-modules/modals/modal-confirm/modal-confirm.module';
import {ModalInfoModule} from '@shared-modules/modals/modal-info/modal-info.module';
import {SpinnerModule} from '@shared-modules/spinner/spinner.module';

import {environment} from '../environments/environment';
import {ModalFindUserModule} from '@shared-modules/modals/modal-find-user/modal-find-user.module';
import {
  ModalDeleteProfileModule
} from '@shared-modules/modals/modal-delete-profile/modal-delete-profile.module';
import {
  ModalManageConsultationModule
} from '@shared-modules/modals/modal-manage-consultation/modal-manage-consultation.module';
import {
  ModalConsultationResultModule
} from '@shared-modules/modals/modal-consultation-result/modal-consultation-result.module';
import {SystemRulesModule} from '@shared-modules/system-rules/system-rules.module';
import {CookieBannerModule} from '@shared-modules/cookie-banner/cookie-banner.module';

registerLocaleData(localeRu, 'ru');
registerLocaleData(localeUa, 'uk');
registerLocaleData(localeEn, 'en');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MyNetworkErrorComponent,
    PatientNetworkErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    SafeImageModule,
    PdfViewerModule,
    PatientsInviteModule,
    AnamnesisModule,
    AppLayoutModule,
    ModalConfirmModule,
    ModalDeleteProfileModule,
    ModalManageConsultationModule,
    ModalInfoModule,
    ModalFindUserModule,
    ModalConsultationResultModule,
    SystemRulesModule,
    SpinnerModule,
    RecaptchaV3Module,
    CookieBannerModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    CalendarModule.forRoot(
      {
        provide: DateAdapter,
        useFactory: adapterFactory
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarFormatterClass
        }
      }
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RecaptchaInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru'
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.siteKey
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.GOOGLE_AUTH_KEY, {oneTapEnabled: false})
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FB_KEY)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    provideEnvironmentNgxMask(),
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
