import {Component, OnInit} from '@angular/core';
import {interval} from 'rxjs';
import * as moment from 'moment';
import {filter, mergeMap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Router} from '@angular/router';

import {
  ConsultationsListFilter
} from '@consultations/interfaces/consultations-list-filter.interface';
import {Consultation} from '@consultations/interfaces/consultation.interface';
import {ProfileService} from '@profile/profile.service';
import {ConsultationsService} from '@consultations/consultations.service';
import {ConsultationStatusesEnum} from '@consultations/enums/consultation-statuses.enum';
import {NearestConsultationService} from '@core/services/nearest-consultation.service';
import {ClinicIdsEnum} from '@core/enums/clinic-ids.enum';
import {TypeCommunicationEnum} from '@core/enums/type-communication.enum';

@UntilDestroy()

@Component({
  selector: 'app-nearest-consultation',
  templateUrl: './nearest-consultation.component.html',
  styleUrls: ['./nearest-consultation.component.scss']
})
export class NearestConsultationComponent implements OnInit {
  params: ConsultationsListFilter = {
    page: 1,
    perPage: 1,
    use_ended_at: 1,
    direction: 'ASC',
    field: 'start_time',
    from_end: moment().utc().unix(),
    statuses: [ConsultationStatusesEnum.Prepare, ConsultationStatusesEnum.Started]
  };

  consultation: Consultation;
  interval = 1000;
  hideTimer = false;
  startDays: number;
  startHours: number;
  startMinutes: number;
  timerSub: any;

  clinicIds = ClinicIdsEnum;
  activeClinic$ = this.profileService.activeClinic$;
  typeCommunicationEnum = TypeCommunicationEnum;

  constructor(
    private profileService: ProfileService,
    private nearestConsultationService: NearestConsultationService,
    private consultationsService: ConsultationsService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getNearestConsultation();
    this.updateNearestConsultation();
  }

  close(): void {
    this.nearestConsultationService.setLocalSetting(false);
  }

  startConsultation(consultation: Consultation): void {
    this.router.navigate(['/chat', consultation.id]);
  }

  private getNearestConsultation(refreshTimer = false): void {
    this.profileService.profile$
      .pipe(
        mergeMap(profile => {
          this.params.doctor_id = profile?.id;
          return this.consultationsService.getConsultationsList(this.params);
        }),
        untilDestroyed(this)
      )
      .subscribe(res => {
        if (!res.data.length) {
          this.consultation = null;
          this.nearestConsultationService.updateNearestConsultation$.next(null);
          return;
        }

        this.consultation = res.data[0];
        this.timeToStartConsultation(this.consultation, refreshTimer);
      });
  }

  private timeToStartConsultation(consultation: Consultation, clearTimer = false): void {
    if (consultation.status === ConsultationStatusesEnum.Started) {
      this.hideTimer = true;
      return;
    }

    if (this.timerSub && clearTimer) {
      this.timerSub.unsubscribe();
    }

    this.timerSub = interval(1000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const duration: number = moment(consultation.start_time).seconds(0).diff(moment());
        const counter = moment.duration(duration - this.interval, 'millisecond');

        if (counter.asMinutes() <= 0) {
          this.hideTimer = true;
          this.timerSub.unsubscribe();
          return;
        }

        this.startDays = counter.days();
        this.startHours = counter.hours();
        this.startMinutes = counter.minutes();
      });
  }

  private updateNearestConsultation(): void {
    this.nearestConsultationService.updateNearestConsultation$
      .pipe(
        filter(b => b),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.getNearestConsultation(true);
      });
  }
}
