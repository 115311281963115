import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import Jsona from 'jsona';

import {ApiJsonBodyInterface, DeserializedJsonInterface} from '../interfaces/jsona.interface';
import * as qs from 'qs';


@Injectable({
  providedIn: 'root'
})
export class JsonaService {
  private dataFormatter: Jsona = new Jsona();

  deserialize<T>(body: ApiJsonBodyInterface): DeserializedJsonInterface<T> {
    return {
      data: this.dataFormatter.deserialize(body, {preferNestedDataFromData: true}) as T,
      meta: body.meta
    };
  }

  makeQueryString(params: Params): string {
    if (!params) {
      return '';
    }

    const cloneParams = structuredClone(params);

    return qs.stringify(cloneParams, {
      encode: true
    });
  }
}
