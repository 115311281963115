import {Component, DestroyRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {AsyncPipe, NgClass, NgIf} from '@angular/common';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {GetHomePathPipe} from '@core/pipes/get-home-path.pipe';
import {MatBadge} from '@angular/material/badge';

import {ClinicSettingsService} from '@core/services/clinic-settings.service';
import {OrdersService} from '../../../../../dashboard/orders/services/orders.service';
import {TranslateModule} from '@core/pipes/translate/translate.module';


@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    MatIcon,
    RouterLink,
    TranslateModule,
    RouterLinkActive,
    NgClass,
    GetHomePathPipe,
    AsyncPipe,
    NgIf,
    MatBadge
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  @Input() minimize!: boolean;

  @Output() minimizeChange: EventEmitter<boolean> = new EventEmitter();

  pendingOrdersCount$ = this.ordersService.pendingOrdersCount$;
  telegram = '';

  constructor(
    private destroyRef: DestroyRef,
    private clinicSettings: ClinicSettingsService,
    private ordersService: OrdersService
  ) {
  }

  ngOnInit(): void {
    this.watchTelegram();
  }

  toggleSidebar() {
    this.minimizeChange.emit(!this.minimize);
  }

  private watchTelegram(): void {
    this.clinicSettings.clinicSettings$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(settings => {
        this.telegram = settings?.telegramLink;
      });
  }
}
