<mat-sidenav-container>
  <mat-sidenav-content>

    <ng-container *ngIf="!appLayoutConfig?.useEmptyHeader; else emptyHeader">
      <app-header
        id="main-header"
        *ngIf="windowSizeConfig?.isTablet || !hideElementsByRoute"
        [isHeaderShortened]="appLayoutConfig?.isHeaderShortened"
        (toggleSidenav)="sidenav.toggle()"
      ></app-header>
    </ng-container>

    <ng-template #emptyHeader>
      <app-empty-header [title]="appLayoutConfig?.headerTitle"></app-empty-header>
    </ng-template>

    <div class="wrapper-layout">
      <!-- sidebar -->
      <ng-container *ngIf="(!hideSidebar && !appLayoutConfig?.useEmptyHeader)">
        <div class="sidebar" [ngClass]="{minimize: minimize}">
          <app-sidebar [(minimize)]="minimize"></app-sidebar>
        </div>
      </ng-container>

      <!-- content -->
      <div
        class="app-layout"
        [ngClass]="{
          mobile_chat: !windowSizeConfig?.isTablet && hideElementsByRoute,
          is_wizard: hideSidebar,
          is_chat: isChat
        }"
      >
        <div class="app-layout-main">
          <app-nearest-consultation
            *ngIf="!hideElementsByRoute && (showNearestConsultation$ | async)"
          ></app-nearest-consultation>

          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </mat-sidenav-content>

  <!-- mobile sidebar -->
  <mat-sidenav #sidenav [mode]="'push'" [position]="'start'">
    <app-sidenav
      class="sidenav"
      *ngIf="!windowSizeConfig?.isLargeDesktop && !appLayoutConfig?.useEmptyHeader"
      (toggleSidenav)="sidenav.toggle()"
      (closeSidenav)="sidenav.close()"
    ></app-sidenav>
  </mat-sidenav>
</mat-sidenav-container>


<app-new-notification-listener
  *ngIf="!appLayoutConfig?.isNewNotificationsHidden"
  (clicked)="sidenav.close()"
></app-new-notification-listener>
