import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.includes('login') ||
      req.url.includes('auth-by-register-token') ||
      req.url.includes('auth-by-email-confirm-token') ||
      req.url.includes('sing-up') ||
      req.url.includes('register') ||
      req.url.includes('auth/reset-password') ||
      req.url.includes('register-doctor')
    ) {
      return this.recaptchaV3Service.execute('importantAction')
        .pipe(
          switchMap(token => {
            if (token) {
              req = req.clone({
                setHeaders: {'X-Recaptcha-Authorization': token}
              });
            }

            return next.handle(req);
          })
        );
    }

    return next.handle(req);
  }
}
