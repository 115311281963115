import {Component, DestroyRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

import {Doctor} from '@core/interfaces/doctor/doctor.interface';
import {NotificationsService} from '../../../notifications/services/notifications.service';
import {ProfileService} from '@profile/profile.service';
import {prepareNewNotification} from '../../../notifications/utils/prepare-notification';
import {NotificationTypesEnum} from '../../../notifications/enums/notification-types.enum';
import {Notification} from '../../../notifications/interfaces/notification.interface';
import {NearestConsultationService} from '@core/services/nearest-consultation.service';
import {CentrifugoSocketService} from '@core/services/centrifugo-socket.service';
import {CentrifugoSocketChannelsEnum} from '@core/enums/centrifugo-socket-channels.enum';
import {logNotificationSubInfo, logSocketInfo} from '@core/utils/socket-logger';
import {MessagesCountsService} from '@core/services/messages-counts.service';
import {OrdersService} from '../../../../dashboard/orders/services/orders.service';
import {OrderStatusesEnum} from '../../../../dashboard/orders/enums/order-statuses.enum';

@Component({
  selector: 'app-new-notification-listener',
  templateUrl: './new-notification-listener.component.html',
  styleUrls: ['./new-notification-listener.component.scss']
})
export class NewNotificationListenerComponent implements OnInit, OnDestroy {
  @Output() clicked: EventEmitter<null> = new EventEmitter();

  socketClient: any;
  socket: any;

  profile: Doctor;

  notificationChannel: string;
  newNotification: Notification | null;

  constructor(
    private router: Router,
    private destroyRef: DestroyRef,
    private profileService: ProfileService,
    private socketService: CentrifugoSocketService,
    private notificationsService: NotificationsService,
    private nearestConsultationService: NearestConsultationService,
    private messagesCountsService: MessagesCountsService,
    private ordersService: OrdersService
  ) {
  }

  ngOnInit(): void {
    this.handleMainSubjects();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromNotificationChannel();
    this.onCloseNewNotification();
  }

  showNewNotification(notification): void {
    this.newNotification = {...prepareNewNotification(notification)};
  }

  onCloseNewNotification(): void {
    this.newNotification = null;
  }

  private handleMainSubjects(): void {
    this.socketService.socketConnectionSuccess$
      .pipe(
        filter((r) => r),
        takeUntilDestroyed(this.destroyRef))
      .subscribe((socket) => {
        this.socketClient = socket;
        this.profile = this.profileService.profile$.value;
        this.notificationChannel = `${CentrifugoSocketChannelsEnum.NOTIFICATION}${this.profile.id}`;

        this.subscribeOnNotificationsChannel();
      });
  }

  private subscribeOnNotificationsChannel(): void {
    this.socket = this.socketClient.getSubscription(this.notificationChannel) || this.socketClient.newSubscription(this.notificationChannel);
    this.socket
      .on('subscribed', (ctx) => {
        logSocketInfo(ctx.channel);
      })
      .on('unsubscribed', (ctx) => {
        logSocketInfo(`${ctx.code}: ${ctx.reason}`);
      })
      .on('publication', (ctx) => {
        this.handleIncomingSocketMessage(ctx.data);
      })
      .subscribe();
  }

  private unsubscribeFromNotificationChannel(): void {
    this.socket.unsubscribe();
    this.socket?.removeAllListeners();
  }

  private handleIncomingSocketMessage(message): void {
    logNotificationSubInfo(message);

    if (message.type === NotificationTypesEnum.OrderChangeStatus && message.old_status === OrderStatusesEnum.pending) {
      this.ordersService.pendingOrdersCount$.next(this.ordersService.pendingOrdersCount$.value - 1);
      return;
    }

    if (message.type === NotificationTypesEnum.OrderCreate) {
      this.ordersService.pendingOrdersCount$.next(this.ordersService.pendingOrdersCount$.value + message.count);
    }

    if (message.type === NotificationTypesEnum.NewDiscussionMessage) {
      this.router.url.includes('/dialogues')
        ? this.notificationsService.newDiscussionNotification$.next(message)
        : this.messagesCountsService.getUnreadMessages()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe();

      if (
        message?.initiator_id === this.profile.id ||
        this.router.url.includes(`dialogues/${message.initiator_id}/${message.discussion_id}`)
      ) {
        return;
      }
    }

    if (
      message.type === NotificationTypesEnum.CancelConsultationPatient ||
      message.type === NotificationTypesEnum.CreateConsultation
    ) {
      this.nearestConsultationService.updateNearestConsultation$.next(true);
    }

    this.onCloseNewNotification();

    setTimeout(() => {
      this.showNewNotification(message);
      this.notificationsService.notificationsUpdated$.next(true);
    }, 500);
  }
}
