import {Component, DestroyRef, OnInit} from '@angular/core';
import {MatSnackBar, MatSnackBarDismiss} from '@angular/material/snack-bar';
import {Crypto} from '@core/classes/crypto.class';
import {TranslateService} from '@ngx-translate/core';
import {filter, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

import {ProfileService} from '@profile/profile.service';
import {localToken} from './app.constants';
import {NetworkStateService} from '@shared-modules/network-state/network-state.service';
import {WindowSizeService} from '@core/services/window-size.service';
import {Doctor} from '@core/interfaces/doctor/doctor.interface';
import {CookieBannerComponent} from '@shared-modules/cookie-banner/cookie-banner.component';
import {CentrifugoSocketService} from '@core/services/centrifugo-socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [NetworkStateService]
})
export class AppComponent implements OnInit {
  crypto: Crypto = new Crypto();

  constructor(
    private destroyRef: DestroyRef,
    private profileService: ProfileService,
    private socketService: CentrifugoSocketService,
    private networkStateService: NetworkStateService,
    private windowSizeService: WindowSizeService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.checkCookie();
    this.storageListener();

    this.setLanguage();
    this.networkStateService.checkNetworkState();
    this.networkStateService.checkBrowser();

    if (this.profileService.isAuthenticated()) {
      this.handleWindowSizeConfig();
    }
  }

  private storageListener(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === this.crypto.encrypt(localToken) && !event.newValue) {
        this.socketService.socketDisconnect();
        this.profileService.logout();
      }
    });
  }

  private handleWindowSizeConfig(): void {
    this.windowSizeService.handleWindowSizeConfig()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private setLanguage(): void {
    const browserLang = this.translateService.getBrowserLang();
    const language = browserLang === 'en' || browserLang === 'uk' || browserLang === 'ru' ? browserLang : 'uk';
    this.translateService.setDefaultLang(language);

    this.profileService.profile$
      .pipe(
        filter(r => !!r),
        mergeMap((profile: Doctor) => {
          if (!profile.lang) {
            return this.profileService.patchLanguage(language).pipe(
              mergeMap(() => this.profileService.loadProfile()),
            );
          }

          return of(profile);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((profile: Doctor) => {
        this.translateService.setDefaultLang(profile.lang);
      });
  }

  private checkCookie(): void {
    if (localStorage.getItem('cookieAccepted')) {
      return;
    }

    const snackBarRef = this.snackBar.openFromComponent(CookieBannerComponent, {
      panelClass: ['cookie-snackbar']
    });

    snackBarRef.afterDismissed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((dismissed: MatSnackBarDismiss) => {
        if (!dismissed?.dismissedByAction) {
          return;
        }

        localStorage.setItem('cookieAccepted', '1');
      });
  }
}
