import {Pipe, PipeTransform} from '@angular/core';
import {homePageToggleStateName} from '../../app.constants';

@Pipe({
  name: 'getHomePath',
  standalone: true,
  pure: false
})
export class GetHomePathPipe implements PipeTransform {

  transform(value: string): string {
    return `${value}/${localStorage.getItem(homePageToggleStateName) === 'dashboard' ? 'dashboard' : 'my-tasks'}`;
  }

}
