<div class="order-confirm">
  <div class="avatar" [class.avatar-exist]="order.patient?.avatar">
    <ng-container *ngIf="order.patient?.avatar; else image">
      <app-safe-image
        [name]="order.patient?.avatar"
        [storage]="order.patient?.avatar_storage"
      ></app-safe-image>
    </ng-container>

    <ng-template #image>
      <img src="/assets/images/default-avatar.svg" alt="avatar client"/>
    </ng-template>
  </div>

  <h2 class="title">
    @if (toStatus === orderStatus.delete) {
      {{'Відхилити' | translate}}
    }

    @if (toStatus === orderStatus.inProcess) {
      {{'Подтвердить' | translate}}
    }

    @if (toStatus === orderStatus.completed) {
      {{'Завершити' | translate}}
    }

    {{'замовлення' | translate}}
  </h2>

  <div class="text text-center">
    @if (toStatus === orderStatus.delete) {
      {{order?.courseId
      ? ('Ви впевнені, що хочете скасувати замовлення на курс' | translate)
      : ('Ви впевнені, що хочете скасувати замовлення на послугу' | translate)
      }}
    }

    @if (toStatus === orderStatus.inProcess && !order?.courseId) {
      {{'Ви впевнені, що хочете підтвердити замовлення на послугу' | translate}}
    }

    @if (toStatus === orderStatus.completed && !order?.courseId) {
      {{'Ви впевнені, що хочете завершити замовлення на послугу' | translate}}
    }

    @if (toStatus === orderStatus.completed && order?.courseId) {
      {{'Ви впевнені, що хочете надати доступ до курсу' | translate}}
    }

    <b>«{{order.name | translate}}»</b>
    {{'для' | translate}}
    {{order.patient?.name}}?
  </div>

  <div class="order-confirm-actions">
    <button class="btn btn-secondary" mat-dialog-close>
      {{'Назад' | translate}}
    </button>

    <button class="btn btn-primary" [mat-dialog-close]="true">
      @if (toStatus === orderStatus.delete) {
        {{'Відхилити' | translate}}
      }

      @if (toStatus === orderStatus.inProcess) {
        {{'Подтвердить' | translate}}
      }

      @if (toStatus === orderStatus.completed && !order.courseId) {
        {{'Завершити' | translate}}
      }

      @if (toStatus === orderStatus.completed && order.courseId) {
        {{'Надати' | translate}}
      }
    </button>
  </div>
</div>
