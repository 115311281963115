<div class="modal_wrap">
  <div class="page-container">
    <p class="section-title">
      {{isDialogueModal ? ('Начать новый чат' | translate) : ('Пригласить клиента' | translate)}}
    </p>

    <app-patients-context-search
      *ngIf="!firstRequest || patients?.length"
      (search)="onSearch($event)"
      [isMyPatientsOnly]="true"
    ></app-patients-context-search>

    <ng-container *ngIf="patients?.length && !isLoading; else emptySection">
      <div class="card-list-patients">
        <div class="card-wrapper" *ngFor="let patient of patients">

          <div class="card patient-card">
            <div class="body">
              <div class="user">
                <div class="avatar" [class.avatar-exist]="patient.avatar">
                  <ng-container *ngIf="patient.avatar; else image">
                    <app-safe-image
                      [name]="patient.avatar"
                      [storage]="patient.avatar_storage"
                    ></app-safe-image>
                  </ng-container>

                  <ng-template #image>
                    <img src="/assets/images/default-avatar.svg" alt="avatar client"/>
                  </ng-template>
                </div>

                <div class="patient_info">
                  <div class="name">
                    <div class="name_full"
                         *ngIf="patient?.name">
                      {{patient?.name}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="actions">
                <button
                  *ngIf="isDialogueModal"
                  class="btn btn-primary"
                  (click)="startDialogue(patient)">
                  {{'Написать' | translate}}
                </button>

                <button
                  *ngIf="!isDialogueModal"
                  class="btn btn-primary"
                  (click)="invitePatient(patient)">{{'Пригласить' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>

        <mat-paginator
          *ngIf="total > 4 && !isLoading"
          [length]="total"
          [pageSize]="params.perPage"
          [pageIndex]="params.page - 1"
          [pageSizeOptions]="[4, 8, 16]"
          (page)="onPageChange($event)"
        ></mat-paginator>
      </div>
    </ng-container>

    <ng-template #emptySection>
      <app-empty-result *ngIf="!isLoading && firstRequest; else empty"
                        [title]="'У Вас пока нет своих клиентов!'">
      </app-empty-result>

      <ng-template #empty>
        <app-empty-result *ngIf="!isLoading"
                          [title]="'По данному запросу ничего не найдено!'">
        </app-empty-result>
      </ng-template>
    </ng-template>

    <app-spinner [isLoading]="isLoading"></app-spinner>
  </div>
</div>
