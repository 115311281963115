<div class="heading">
  <app-language-select></app-language-select>
</div>

<mat-nav-list
  class="app-nav-list"
  (click)="toggleSidenav.emit()"
>

  <ul class="sidebar-menu">
    <li
      [routerLinkActive]="['active']"
      class="menu-item mb-3"
    >
      <a [routerLink]="['/home' | getHomePath]" class="flex items-center">
        <mat-icon class="mr-2" fontSet="material-symbols-outlined">home</mat-icon>
        <span>{{'Главная' | translate}}</span>
      </a>
    </li>

    <li
      [routerLinkActive]="['active']"
      class="menu-item my-3"
    >
      <a [routerLink]="['/consultations']" class="flex items-center">
        <mat-icon class="mr-2" fontSet="material-symbols-outlined">videocam</mat-icon>
        <span>{{'Консультации' | translate}}</span>
      </a>
    </li>

    <li
      [routerLinkActive]="['active']"
      class="menu-item my-3"
    >
      <a
        [routerLink]="['/orders']"
        class="inline-flex items-center relative"
      >
        <mat-icon class="mr-2" fontSet="material-icons-outlined">summarize</mat-icon>
        <span>{{'Заказы' | translate}}</span>
        <span
          *ngIf="(pendingOrdersCount$ | async)"
          class="orders_icon"
          [matBadge]="(pendingOrdersCount$ | async) > 99 ? '99+' : (pendingOrdersCount$ | async)"
        >
        </span>
      </a>
    </li>

    <li
      [routerLinkActive]="['active']"
      class="menu-item my-3"
    >
      <a [routerLink]="['/schedule']" class="flex items-center">
        <mat-icon class="mr-2" fontSet="material-symbols-outlined">event</mat-icon>
        <span>{{'Расписание' | translate}}</span>
      </a>
    </li>

    <li
      [routerLinkActive]="['active']"
      class="menu-item my-3"
    >
      <a [routerLink]="['/my-clients']" class="flex items-center">
        <mat-icon class="mr-2" fontSet="material-icons-outlined">groups</mat-icon>
        <span>{{'Клиенты' | translate}}</span>
      </a>
    </li>

    <li
      [routerLinkActive]="['active']"
      class="menu-item my-3"
    >
      <a [routerLink]="['/my-services']" class="flex items-center">
        <mat-icon class="mr-2" fontSet="material-symbols-outlined">handshake</mat-icon>
        <span>{{'Услуги' | translate}}</span>
      </a>
    </li>

    <li
      [routerLinkActive]="['active']"
      class="menu-item my-3"
    >
      <a [routerLink]="['/all-documents']" class="flex items-center">
        <mat-icon class="mr-2" fontSet="material-symbols-outlined">file_copy</mat-icon>
        <span>{{'Документы' | translate}}</span>
      </a>
    </li>

    <li
      [routerLinkActive]="['active']"
      class="menu-item my-3"
    >
      <a [routerLink]="['/recipes']" class="flex items-center">
        <mat-icon class="mr-2" fontSet="material-symbols-outlined">nutrition</mat-icon>
        <span>{{'Продукти' | translate}}</span>
      </a>
    </li>
  </ul>
</mat-nav-list>

<div class="nav_footer mt-auto">
  <ul class="sidebar-menu">
    <li
      class="menu-item my-3"
    >
      <a [href]="telegram" target="_blank" class="flex items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M21 5L2 12.5L9 13.5M21 5L18.5 20L9 13.5M21 5L9 13.5M9 13.5V19L12.249 15.723"
                stroke="#37474F" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        <span class="ml-2">{{'Поддержка' | translate}}</span>
      </a>
    </li>

    <li
      [routerLinkActive]="['active']"
      class="menu-item mt-3"
    >
      <a [routerLink]="['/support']" class="flex items-center">
        <mat-icon class="mr-2" fontSet="material-symbols-outlined">contact_support</mat-icon>
        <span>{{'Помощь' | translate}}</span>
      </a>
    </li>
  </ul>
</div>
