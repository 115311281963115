import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ModalConsultationResultComponent} from './modal-consultation-result.component';


@NgModule({
  declarations: [
    ModalConsultationResultComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ModalConsultationResultModule {
}
