<div class="header">
  <div class="header_container">
    <div class="logo">
      <img src="assets/images/logo.svg" alt="Ponedilok">
    </div>
  </div>
</div>

<div class="container">
  <div class="content">
    <h1>Політика конфіденційності</h1>
    <p>Дата публікації: 27.05.2024</p>

    <p>Ласкаво просимо до <b>Ponedilok</b> – Платформи, що створена для надання Користувачеві доступу до її Послуг.</p>
    <p>Політика Конфіденційності регламентує порядок забезпечення безпеки Персональних та Статистичних даних під час отримання Послуг Ponedilok.</p>
    <p>У цьому документі ми описуємо способи збирання, обробки, зберігання, розкриття та передачі Персональних та Статистичних даних під час отримання Послуг Ponedilok.</p>
    <p>Положення цієї Політики Конфіденційності застосовуються тільки до Персональних та Статистичних даних, отриманих Платформою способами, описаними в її умовах.</p>
    <p>Посилання на слова “Ви” або “Ваш” (або близькі за змістом слова) означають Користувача залежно від контексту Політики Конфіденційності.</p>
    <p>Посилання на слова “ми”, “наш” або “нас” (або аналогічні слова) означають Платформу Ponedilok.</p>
    <p>Слова він/вона та їх похідні в тексті документа можуть застосовуватися як до обличчя чоловічої, так і до жіночої статі, залежно від контексту документа.</p>

    <h2>1.  ТЕРМІНИ ТА ВИЗНАЧЕННЯ</h2>
    <p>1.1.         <b>Замовник (Клієнт)</b> – це будь-яка фізична особа, що замовляє консультацію або іншу послугу в Консультанта шляхом використання Сайту Платформи.</p>
    <p>1.2.         <b>Консультант</b> – це будь-яка фізична особа-підприємець або юридична особа, що реєструється на Сайті з метою надання послуг пізнавального характеру (не надає консультацію як таку, що породжує будь-які юридичні наслідки та не несе в собі інформацію дійсного/фактичного та/або дорадчого змісту) Замовнику у сфері нутриціології та здорового образу життя, що вказані у відповідному розділі Сайту.</p>
    <p>1.3.         <b>Користувач</b> – це загальна назва для Консультанта та Замовника.</p>
    <p>1.4.         <b>Консультація</b> – це інформаційна послуга, що надається Замовнику виключно з метою отримання пізнавальної інформації. Послуга не має характеру консультації, що може призводити до юридичних наслідків, та не включає в себе дійсну/фактичну та/або дорадчу інформацію, надану Консультантом на підставі оплаченої заявки Замовника. Ця послуга надається Замовнику за технічної підтримки Сайту і на умовах індивідуального обмеженого доступу.</p>
    <p>1.5.         <b>Згода Користувача (далі «Згода»)</b> — це добровільне, конкретне, проінформоване та недвозначне волевиявлення, в якому Користувач за допомогою заяви чи явної позитивної дії погоджується на обробку його Персональних та Статистичних даних.</p>
    <p>1.6.         <b>Персональні дані</b> —  це будь-яка інформація, яка знаходиться в закритому доступі, що дозволяє прямо або опосередковано ідентифікувати Користувача. Наприклад, email, номер телефону, ПІБ.</p>
    <p>1.7.         <b>Статистичні дані</b> — це будь-яка інформація, яка знаходиться у відкритому доступі та прямо чи опосередковано належать Користувачу. Наприклад, дані про адресу Консультанта, назву юридичної особи тощо.</p>
    <p>1.8.         Платформа <b>“Ponedilok” (надалі “Платформа” або “Ponedilok”)</b> – це сервіс, що створений для надання можливості Замовнику обрати Консультанта з метою отримання консультації, а також надання можливості Консультантам здійснювати свою діяльність з метою надання послуг Замовнику.</p>
    <p>1.9.         Вебсайт Платформи <b>“Ponedilok” (надалі “Сайт”)</b> – означає вебсторінку або групу вебсторінок в мережі Інтернет, які розміщені за адресою <a href="https://Ponedilok.co" target="_blank">https://Ponedilok.co</a>, за допомогою яких Платформа  надає Послуги Користувачам.</p>
    <p>1.10.       <b>Послуги</b> — це алгоритм дій, що виконуються Платформою для надання Консультанту можливості розмістити своє оголошення з метою надання консультації Замовнику,  а також надання можливості Замовнику обрати Консультанта з метою отримання професійної консультації в сфері, що його цікавить.</p>
    <p>1.11.       <b>Третя особа</b> – означає фізичну або юридичну особу, державну установу, установу або орган, відмінні від Користувача та Платформи.</p>
    <p>1.12.       <b>Cookie</b> – це фрагмент інформації у вигляді тексту або двійкових даних, які використовуються Платформою під час використання Користувачем Сайту.</p>

    <h2>2.  ОСНОВНІ ПОЛОЖЕННЯ</h2>
    <p>2.1.         Платформа обробляє та зберігає Персональні дані на основі принципів законності, справедливості та прозорості відповідно до <b><a href="https://zakon.rada.gov.ua/laws/show/2297-17%23Text" target="_blank">Закону України «Про захист персональних даних.</a></b></p>
    <p>2.2.         Платформа не несе відповідальності за обробку Персональних та Статистичних даних будь-якими Третіми особами, які не контролюються або не належать Ponedilok.</p>

    <h2>3.  ПЕРСОНАЛЬНІ ТА СТАТИСТИЧНІ ДАНІ</h2>
    <p>3.1.         Платформа може отримувати наступні Персональні дані Замовника:</p>
    <p>3.1.1.         повне ім’я;</p>
    <p>3.1.2.         контактний телефон;</p>
    <p>3.1.3.         дата народження;</p>
    <p>3.1.4.         e-mail;</p>
    <p>3.1.5.         логін та пароль, що використовується під час реєстрації.</p>
    <p>3.1.6.         фотозображення</p>
    <p>3.2.         Платформа може отримувати наступні Персональні дані Консультанта:</p>
    <p>3.2.1.         повне ім’я;</p>
    <p>3.2.2.         стать</p>
    <p>3.2.3.         контактний телефон;</p>
    <p>3.2.4.         платіжні/банківські реквізити, виключно для отримання оплати підписки на платформі. Платформа повідомляє, що платіжні дані Консультанта (номер банківської карти, дата та CVV код тощо), обробляються виключно платіжним сервісом та/або банком, що використовуються Платформою для надання Послуг;</p>
    <p>3.2.5.         дата народження;</p>
    <p>3.2.6.         e-mail;</p>
    <p>3.2.7.         фотозображення;</p>
    <p>3.2.8.       логін та пароль, що використовується під час реєстрації.</p>
    <p>3.3.         Платформа може отримувати такі Статистичні дані Консультанта:</p>
    <p>3.3.1.         дані про сферу діяльності та спеціалізацію;</p>
    <p>3.3.2.         досвід роботи;</p>
    <p>3.3.3.         мову надання консультації;</p>
    <p>3.3.4.         дані про працівників, що знаходяться в відкритому доступі.</p>
    <p>3.4.         Платформа повідомляє, що вона може отримати Статистичні дані Замовника, що публікуються ним самостійно під час отримання Послуг, включаючи розміщення Статистичних даних у відгуках та/або під час комунікації із працівниками Платформи. В разі отримання таких Статистичних даних, вони обробляються Платформою, відповідно до умов цієї Політики Конфіденційності.</p>
    <p>3.5.         Платформа може збирати Персональні та Статистичні дані під час спілкування з Користувачем, отримані за допомогою соціальних мереж, месенджерів, електронної пошти, телефонного зв’язку. В такому разі такі дані будуть оброблятися відповідно до цієї Політики Конфіденційності.</p>
    <p>3.6.         <b><i>Ponedilok ціленаправлено не збирає та не обробляє чутливі Персональні дані Користувача, такі як расова чи етнічна приналежність, політичні погляди, релігійні чи філософські переконання, членство у профспілці, генетичні чи біометричні дані, інформація про здоров’я, сексуальне життя чи сексуальну орієнтацію. В разі надання таких даних Користувачем вони обробляються Платформою відповідно до умов цієї Політики Конфіденційності та можуть бути видалені Платформою без попередження Користувача.</i></b></p>
    <p>3.7.         Платформа може збирати Персональні та Статистичні дані Користувача під час написання ним відгуку на Сайті і такі дані обробляються відповідно до умов цієї Політики Конфіденційності. Але, такі дані будуть доступні у публічному доступі і Платформа не несе жодної відповідальності в разі протиправного використання таких даних або їх розголошення будь-якою Третьою особою.</p>
    <p>3.8.       Під час використання Сайту або отримання Послуг, Платформа може автоматично збирати наступні Персональні та Статистичні дані Замовника:</p>
    <p>3.10.1.       IP-адреса;</p>
    <p>3.10.2.       налаштування часового поясу та мови;</p>
    <p>3.10.3.       операційна система, вид пристрою та розширення екрану;</p>
    <p>3.10.4.       країна, де ви перебуваєте;</p>
    <p>3.10.5.       дані про Ваш візит Сайту, включаючи повну інформацію URL, маршруту переходів на Сайт та при переході на Сайт/виході з нього (включаючи дату та час), час відгуку сторінки, помилки завантаження даних, тривалість перебування на певних сторінках, активність на сторінці (така інформація, як прокрутка та руху миші, кліки), методи, використовувані для виходу зі сторінки та номери телефонів, за якими зверталися до центру обслуговування клієнтів.</p>
    <p>3.11.       <b><u>Платформа має право збирати та отримувати Персональні та Статистичні дані таким чином:</u></b></p>
    <p>3.11.1.       під час надання Послуг;</p>
    <p>3.11.2.       під комунікації із Користувачем;</p>
    <p>3.11.3.       під час написання відгуку;</p>
    <p>3.11.4.       під час використання Сайту;</p>
    <p>3.11.5.       при взаємодії з працівниками Платформи шляхом обміну електронними листами/повідомленнями, спілкування з працівниками Платформи;</p>
    <p>3.11.6.       під час моніторингу та оцінки дій Користувача для виявлення та запобігання шахрайству;</p>
    <p>3.11.7.       використання файлів журналів, файлів Cookie та інших технологій відстеження;</p>
    <p>3.11.8.       за допомогою месенджерів, соціальних мереж та електронної пошти.</p>
    <p>3.12.       <b><u>Платформа має право використовувати Персональні та Статистичні дані в таких цілях:</u></b></p>
    <p>3.12.1.       надання Послуг;</p>
    <p>3.12.2.       публікації на своєму Сайті та/або акаунтах соціальних мереж, що належить Платформі;</p>
    <p>3.12.3.       для забезпечення безпеки Сайту;</p>
    <p>3.12.4.       для адміністрування Сайту та проведення внутрішніх операцій, включаючи усунення несправностей, аналіз даних, тестування та заповнення опитувань;</p>
    <p>3.12.5.       для покращення навігації Сайту;</p>
    <p>3.12.6.       спілкування з Користувачем;</p>
    <p>3.12.7.       відповіді на запити правоохоронних органів;</p>
    <p>3.12.8.       щоб ініціювати відповіді на юридичні претензії, розслідування чи вирішення спорів із Користувачем.</p>

    <h2>4.  ПЕРЕДАЧА ПЕРСОНАЛЬНИХ/СТАТИСТИЧНИХ ДАНИХ</h2>
    <p>4.1.         Ponedilok може передавати Персональні або Статистичні дані суб’єктам, з якими Платформа співпрацює для надання Послуг:</p>
    <p>4.1.1.         <b>Платіжний сервіс/Банк.</b> Для здійснення взаєморозрахунків під час оплати підписки на Платформу, Персональні та Статистичні дані можуть бути передані платіжному сервісу/банку.</p>
    <p>4.1.2.         <b>Підрядники та співробітники.</b> Платформа може передавати ваші Персональні та Статистичні дані іншим особам, з якими вона розпочинає співпрацю, у тому числі юридичним та податковим консультантам, а також особам, які надають бухгалтерські, логістичні, маркетингові та ІТ-послуги.</p>
    <p>4.1.3.         <b>Контрагенти.</b> Платформа має право розкривати або передавати Персональні та Статистичні дані у таких угодах: придбання або злиття, фінансування, корпоративна реорганізація, спільне підприємство, включаючи продаж активів або банкрутство.</p>
    <p>4.1.4.         <b>Суди, правоохоронні органи та державні органи.</b> Платформа має право передавати Персональні та Статистичні дані у разі виконання будь-яких юридичних зобов’язань, у тому числі за рішенням суду.</p>
    <p>4.1.5.         <b>Партнери.</b> Платформа може передавати Персональні та Статистичні дані своїм партнерам, якщо така передача необхідна для надання Послуг.</p>
    <p>4.1.6.         <b>Представники.</b> Платформа може передавати Персональні та Статистичні дані своїм законним представникам, таким як довірена особа, представник тощо.</p>
    <p>4.1.7.         <b>Amazon Web Services, Inc.</b> Сайт розміщено на хостингу <a href="https://aws.amazon.com" target="_blank">https://aws.amazon.com.</a> Адреса: P.O. Box 81226 Seattle, WA 98108-1226. Персональні та Статистичні дані можуть бути передані цій компанії. Більш детальніше з умовами оброблення Персональних та Статистичних даних можна ознайомитись за <a href="https://aws.amazon.com/ru/terms/?nc1=f_prrms-of-service-agreementor-oferta-mirohost" target="_blank">посиланням.</a></p>
    <p>4.1.8.         <b>Замовнику.</b> Платформа може передавати Персональні та Статистичні дані Консультанта Замовнику, відповідно до його запиту для врегулювання спорів.</p>
    <p>4.1.9.         <b>Консультанту.</b> Платформа може передавати Персональні та Статистичні дані Замовника Консультанта відповідно до його запиту для врегулювання спорів.</p>

    <h2>5.  ЗГОДА КОРИСТУВАЧА</h2>
    <p>5.1.         Одна з наступних дій Користувача означають Згоду з умовами цієї Політики Конфіденційності:</p>
    <p>5.1.1.         отримання Послуг;</p>
    <p>5.1.2.         реєстрація на Сайті.</p>
    <p>5.2.         Погоджуючись з умовами цієї Політики Конфіденційності, Замовник надає свою Згоду на:</p>
    <p>5.2.1.         використання та обробку його Персональних та Статистичних даних, відповідно до умов Політики Конфіденційності;</p>
    <p>5.2.2.         надання Платформі додаткової інформації та Персональних та Статистичних даних на запит Платформи;</p>
    <p>5.2.3.         передачу його даних стороннім сервісам, відповідно до умов цієї Політики Конфіденційності.</p>
    <p>5.3.         Погоджуючись з умовами цієї Політики Конфіденційності, Консультант надає свою Згоду на:</p>
    <p>5.3.1.         використання та обробку його Персональних та Статистичних даних, відповідно до умов Політики Конфіденційності;</p>
    <p>5.3.2.         надання Платформі додаткової інформації та Персональних та Статистичних даних на запит Платформи;</p>
    <p>5.3.3.         обробку його платіжних реквізитів платіжним сервісом/банком відповідно до умов цієї Політики Конфіденційності;</p>
    <p>5.3.4.         передачу його даних стороннім сервісам, відповідно до умов цієї Політики Конфіденційності;</p>
    <p>5.3.5.         надання інформації щодо своєї кваліфікації, спеціалізації, умов надання послуг Замовнику;</p>
    <p>5.3.6.         надання документації Платформі, відповідно до умов цієї Політики Конфіденційності;</p>
    <p>5.3.7.         надання консультації Замовнику, відповідно до умов цієї Політики Конфіденційності.</p>
    <p>5.4.         Платформа залишає за собою право отримати Згоду Користувача через форму, що випливає. Випливаюча форма може містити форму Згоди у вигляді checkbox (прапорця).</p>

    <h2>6.  ЗБЕРІГАННЯ ТА ЗАХИСТ ПЕРСОНАЛЬНИХ/СТАТИСТИЧНИХ ДАНИХ</h2>
    <p>6.1.         Платформа використовує всі необхідні заходи безпеки та захисту Персональних та Статистичних даних для забезпечення їх конфіденційності та запобігання втрати або неправомірному розкриттю.</p>
    <p>6.2.         Платформа захищає та зберігає Персональні та Статистичні дані від:</p>
    <p>6.2.1.         втрати;</p>
    <p>6.2.2.         незаконного використання, передачі, розкриття, модифікації, видалення та/або знищення.</p>
    <p>6.3.         Ponedilok має право зберігати Персональні та Статистичні дані Замовника протягом <b>3 (трьох) років</b> після припинення відносин у таких випадках:</p>
    <p>6.3.1.         для статистичного обліку;</p>
    <p>6.3.2.         якщо чинне законодавство потребує їх зберігання;</p>
    <p>6.3.3.         якщо вони необхідні для ведення бізнесу;</p>
    <p>6.3.4.         для здійснення маркетингової аналітики.</p>


    <h2>7.  ПІДСТАВИ ДЛЯ ОБРОБКИ ПЕРСОНАЛЬНИХ/СТАТИСТИЧНИХ ДАНИХ</h2>
    <p>7.1.         Платформа обробляє Персональні та Статистичні дані на наступних законних підставах:</p>
    <p>7.1.1.         Згода Користувача;</p>
    <p>7.1.2.         отримання Послуг від Платформи;</p>
    <p>7.1.3.         реєстрація/авторизація на Сайті.</p>
    <p>7.2.         У випадках, коли підставою для обробки Персональних та Статистичних даних є ваша Згода, ви маєте право відкликати її у будь-який час. Щоб відкликати свою згоду, ви можете надіслати електронний лист за адресою: <a href="mailto:support@ponedilok.co" >support&#64;ponedilok.co</a>. У разі відкликання вашої Згоди, Платформа має право припинити надання Послуг та припинити всі стосунки з вами. Якщо Згода буде відкликана, ваші Персональні та Статистичні дані будуть безповоротно видалені.</p>
    <p>7.3.         Платформа зобов’язується припинити обробку Персональних та Статистичних даних протягом <b>15 (п’ятнадцяти) робочих днів</b> з моменту отримання відкликання Згоди.</p>

    <h2>8.  ПРАВА КОРИСТУВАЧА</h2>
    <p>8.1.         Умови цього пункту застосовуються для Користувачів та стосується конкретних вимог, відповідно до положень законодавства України, зокрема Закону України «Про захист персональних даних»:</p>
    <p>8.1.1.         отримувати інформацію про умови надання доступу до Персональних  та Статистичних даних, зокрема інформацію про Третіх осіб, яким вони передаються;</p>
    <p>8.1.2.         отримувати інформацію, не пізніш як за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи обробляються Персональні та Статистичні дані;</p>
    <p>8.1.3.         надіслати вмотивовану вимогу, щодо заперечення обробки Персональних  та Статистичних даних;</p>
    <p>8.1.4.         надіслати вмотивовану вимогу, щодо зміни або видалення Персональних  та Статистичних даних;</p>
    <p>8.1.5.         на захист Персональних та Статистичних даних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв’язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію Користувача;</p>
    <p>8.1.6.         звертатися із скаргами на обробку Персональних  та Статистичних даних до суду;</p>
    <p>8.1.7.         застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;</p>
    <p>8.1.8.         вносити застереження стосовно обмеження права на обробку Персональних  та Статистичних даних під час надання згоди;</p>
    <p>8.1.9.         відкликати згоду на обробку Персональних  та Статистичних даних;</p>
    <p>8.1.10.       знати механізм автоматичної обробки Персональних  та Статистичних даних;</p>
    <p>8.1.11.       на захист від автоматизованого рішення, яке має для нього правові наслідки.</p>

    <h2>9.  ПОДАЧА ЗАПИТУ</h2>
    <p>9.1.         Користувач має право подати запит до Платформи, якщо він вважає, що його права були порушені, написавши запит у письмовій формі на підтримку Платформи за адресою: <a href="mailto:support@ponedilok.co" >support&#64;ponedilok.co</a>.</p>
    <p>9.2.         Запит Користувача повинен містити достовірну інформацію щодо вимог до Платформи. Якщо у запиті не вказані точні вимоги, Платформа має право відмовити у виконанні запиту.</p>
    <p>9.3.         Ми не зможемо відповісти на Ваш запит або надати Вам Персональні та Статистичні дані, якщо ми не зможемо підтвердити вашу особу та підтвердити, що Персональні та Статистичні дані належать Вам. У разі отримання запиту з недостовірною інформацією та/або у разі неможливості підтвердити особу Користувача, Платформа має право не обробляти отриманий запит та звернутися до Користувача за роз’ясненнями. У разі отримання відповіді на запит про роз’яснення, Користувач повинен надати новий виправлений запит або подати новий запит.</p>
    <p>9.4.         Платформа зобов’язана відповісти на запит або виконати умови, викладені у запиті протягом <b>15 (п’ятнадцяти) робочих днів</b> з моменту його отримання.</p>

    <h2>10. ВІДПОВІДАЛЬНІСТЬ</h2>
    <p>10.1.       <b><u>Платформа інформує Замовника про те, що Персональні та Статистичні дані можуть бути використані для надання Послуг, і в разі незгоди з цим Користувач не має права на отримання Послуг.</u></b></p>
    <p>10.2.       Платформа не несе відповідальності у разі надання Користувачем недостовірних Персональних та Статистичних даних, у тому числі, неможливість отримання Послуг Користувачем.</p>
    <p>10.3.       Платформа не несе відповідальності за будь-які збитки, заподіяні Користувачу в результаті використання його Персональних та Статистичних даних Третьою особою без його відома, включаючи сторонні сервіси (партнери, платіжні системи, сервіси вебаналітики тощо), оскільки його Персональні та Статистичні дані обробляються відповідно до політик конфіденційності таких сервісів.</p>
    <p>10.4.       Платформа ціленаправлено не перевіряє контент та дані, що публікуються Користувачем на сторінках Сайту, включаючи інформацію та дані, що можуть розміщуватись Користувачем у відгуках. Платформа може перестати надавати Послуги Користувачу та обмежити доступ до Послуг, якщо Користувач розміщує контент/інформацію, що містить:</p>
    <p>10.4.1.       будь-яким чином порушує права Третьої особи, включаючи майнові та/або інтелектуальні права;</p>
    <p>10.4.2.       містить незаконну інформацію;</p>
    <p>10.4.3.       містить неправдиву або неправильну інформацію;</p>
    <p>10.4.4.       містить ознаки порнографічного чи сексуального характеру, ненормативну лексику;</p>
    <p>10.4.5.       принижує честь та гідність Третьої особи, інших Користувачів/Третіх осіб;</p>
    <p>10.4.6.       розпалює міжрасову, міжнаціональну, релігійну, статеву та політичну ненависть;</p>
    <p>10.4.7.       порушує права та/або свободи інших Користувачів/Третіх осіб;</p>
    <p>10.4.8.       містить посилання на вебсайти третіх осіб, які можуть завдати шкоди програмному забезпеченню інших Користувачів/Третіх осіб;</p>
    <p>10.4.9.       містить посилання на сайти, що розповсюджують порнографію, неліцензійне програмне забезпечення та/або медіафайли або розпалюють міжрасову, етнічну, релігійну, сексуальну та політичну ненависть;</p>
    <p>10.4.10.     містить посилання на інформацію, яка є відкритими рекламними майданчиками для мережевого маркетингу, партнерського маркетингу, фінансових пірамід, заробітку вдома та інших видів шахрайства;</p>
    <p>10.4.11.     містить нецензурну лексику;</p>
    <p>10.4.12.     містить жаргонізми у розмірі більше 30 (тридцяти) відсотків утримання;</p>
    <p>10.4.13.     містить флуд;</p>
    <p>10.4.14.     містить різні способи залучення потенційних клієнтів, такі як швидко розбагатіти, фінансова незалежність і т. д.;</p>
    <p>10.4.15.     містить пропозиції щодо реструктуризації кредитів та звільнення від боргів;</p>
    <p>10.4.16.     містить рекламу алкогольної та/або тютюнової продукції;</p>
    <p>10.4.17.     містить спам;</p>
    <p>10.4.18.     містить заклики до насильства;</p>
    <p>10.4.19.     містить наклеп;</p>
    <p>10.4.20.     використовує кілька згрупованих розділових знаків;</p>
    <p>10.4.21.     містить посилання на сайти, які є службами знайомств, ескорту тощо;</p>
    <p>10.4.22.     містити ознаки жебракування;</p>
    <p>10.4.23.     містить посилання на вебсайти та/або інформацію про курси особистісного зростання, розвитку кар’єри тощо.</p>
    <p>10.5.       Перелік вимог, зазначених у пункті 10.4. цього розділу цієї Політики Конфіденційності, не є вичерпним, та Платформа має право на свій розсуд припинити надання Послуг, якщо Ponedilok має підстави вважати, що розміщення такого контенту може завдати шкоди Третій особі, діловій репутації Платформи або порушує чинне законодавство України.</p>
    <p>10.6.       Консультант зобов’язується:</p>
    <p>10.6.1.       обробляти Персональні та Статистичні дані Замовника, відповідно до законодавства України;</p>
    <p>10.6.2.       забезпечити збереження Персональних та Статистичних даних Замовника;</p>
    <p>10.6.3.       відшкодувати всі збитки Замовнику, в разі розголошення його Персональних та Статистичних даних;</p>
    <p>10.6.4.       забезпечити юридичне вирішення спорів та судових проваджень, що виникли внаслідок розголошення Персональних та Статистичних даних Замовників, включаючи захист прав та інтересів Платформи та її афілійованих осіб.</p>
    <p>10.7.       Платформа може посилатися на інші вебсайти чи послуги. Політика Конфіденційності цих вебсайтів та служб не регулюється умовами цієї Політики Конфіденційності, і Платформа не може нести відповідальності за обробку Персональних та Статистичних даних Користувача сторонніми вебсайтами та службами.</p>
    <p>10.8.       Ponedilok не несе відповідальності за:</p>
    <p>10.8.1.       втрати Персональних та Статистичних даних у разі хакерської атаки, злому програмного забезпечення Платформи або Сайту, неправомірних дій Третіх осіб, а також дій, що порушують умови Політики Конфіденційності;</p>
    <p>10.8.2.       неможливість надання Послуг або неякісне надання Послуг у разі надання Користувачем недостовірних Персональних та Статистичних даних чи будь-якої іншої інформації;</p>
    <p>10.8.3.       будь-які збої та неполадки у роботі Сайту, що виникли не з вини Платформи;</p>
    <p>10.8.4.       використання Користувачем Персональних та Статистичних даних іншого Користувача в протизаконних цілях;</p>
    <p>10.8.5.       надання Користувачем неправдивої інформації.</p>
    <p>10.9.       На жаль, передача інформації через Інтернет не може бути повністю безпечною. Хоча ми докладаємо всіх зусиль для захисту Персональних та Статистичних даних, ми не можемо гарантувати безпечний процес передачі Персональних та Статистичних даних до Сайту. У зв’язку з цим ви несете одноосібну відповідальність за можливі збої при передачі ваших Персональних та Статистичних даних до Платформи.</p>

    <h2>11. COOKIE</h2>
    <p>11.1.       Файли Cookie не передають віруси та/або шкідливе програмне забезпечення на Ваш пристрій, оскільки дані у файлах Cookie не змінюються під час передачі та ніяк не впливають на продуктивність вашого пристрою. Вони більше схожі на журнали (тобто записують дії Користувача і запам’ятовують інформацію про стан) і оновлюються щоразу, коли ви відвідуєте Сайт.</p>
    <p>11.2.       При наданні Послуг ми можемо використовувати такі типи файлів Cookies:</p>
    <p>11.2.1.       <b>Сеансові</b> файли Cookies, також відомі як тимчасові файли Cookies, існують тільки в тимчасовій пам’яті, поки Користувач знаходиться на сторінці Сайту. Браузери зазвичай видаляють Cookies сеансу після того, як Користувач закрив вікно браузера.</p>
    <p>11.2.2.       <b>Постійні</b> файли Cookies видаляються в певний день або через певний період часу. Це означає, що інформація про файлах Cookies буде передаватися на сервер кожен раз, коли Користувач відвідує вебсайт, якому належать ці файли Cookies.</p>
    <p>11.2.3.       <b>Сторонні</b> файли Cookies – це тип файлів, які з’являються, коли вебсторінка містить стислі контент з зовнішніх вебсайтів, наприклад посилання на інші вебсайти. Наприклад, вони можуть використовуватися для відстеження історії відвідувань Користувачем інших вебсайтів.</p>
    <p>11.2.4.       <b>Файли Cookies вебаналітики</b> використовуються для аналізу поведінки Замовників при перегляді різних сторінок Сайту.</p>
    <p>11.3.       Платформа використовує файли Cookie для таких цілей:</p>
    <p>11.3.1.       автентифікація та ідентифікація;</p>
    <p>11.3.2.       проведення вебаналітики;</p>
    <p>11.3.3.       дослідження маркетингової аналітики;</p>
    <p>11.3.4.       дослідження уподобань Замовників;</p>
    <p>11.3.5.       зберігання особистих переваг та налаштувань;</p>
    <p>11.3.6.       відстеження сеансів доступу;</p>
    <p>11.3.7.       зберігання статистичних даних;</p>
    <p>11.3.8.       аналіз Сайту.</p>
    <p>11.4.       Платформа використовує наступні сервіси вебаналітики:</p>
    <p>11.4.1.       Google Analytics;</p>
    <p>11.4.2.       власна систем вебаналітики.</p>
    <p>11.5.       Для захисту від спаму на нашому вебсайті встановлено капчу від Google Inc. Адреса: 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA – <b>reCAPTCHA</b>. Детальну інформацію можна знайти у <b><a href="https://policies.google.com/privacy?hl=en" target="_blank">політиці приватності Google</a></b> та у <b><a href="https://www.google.com/recaptcha/about/" target="_blank">документації reCAPTCHA</a></b>.</p>
    <p>11.6.       У багатьох випадках веббраузери за замовчуванням дозволяють зберігати файли Cookie на кінцевому пристрої Замовника. Замовники вебсайту можуть будь-коли змінити налаштування файлів Cookie, таким чином, щоб блокувати автоматичну обробку файлів Cookie або повідомляти про кожне їх розміщення на пристрої Замовника вебсайту. Детальна інформація про можливості та способи обробки файлів Cookie доступна в налаштуваннях вашого браузера.</p>

    <h2>12. ПОЛІТИКА ПОВНОЛІТНІХ</h2>
    <p>12.1.       Послуги Платформи призначені для дієздатних фізичних осіб, які досягли вісімнадцятирічного віку та для юридичних осіб. Платформа не обробляє Персональні та/або Статистичні дані осіб, які не досягли такого віку.</p>
    <p>12.2.       Платформа має право видалити Персональні та Статистичні дані особи, яка не досягла вісімнадцятирічного віку, без попередження та будь-яких наслідків для Платформи, а також припинити надання Послуг такій особі.</p>
    <p>12.3.       Реєструючись на Сайті, Користувач підтверджує, що він є дієздатною фізичною особою, яка діє від власного імені або на законних підставах від імені юридичної особи.</p>

    <h2>13. ЗМІНА ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</h2>
    <p>13.1.       Ми маємо право періодично вносити зміни до Політики Конфіденційності для безпеки Персональних та Статистичних даних, та дотримання вимог законодавства України.</p>
    <p>13.2.       Користувач повинен ознайомитися з новими умовами Політики Конфіденційності та Ponedilok не несе відповідальності, якщо Замовник не ознайомився з новими умовами Політики Конфіденційності.</p>
    <p>13.3.       Ponedilok оновлює дату зміни поточної версії Політики Конфіденційності у рядку «Оновлено» у верхній частині документа.</p>
    <p>13.4.       Наші електронні копії Політики Конфіденційності вважаються вірними, повними, дійсними, що мають юридичну силу і діють в момент відвідування вами Сайту.</p>

    <h2>14. КОНТАКТИ</h2>
    <p>14.1.       Користувач має право звернутися до служби підтримки Платформи за адресою: <a href="mailto:support@ponedilok.co" target="_blank">support&#64;ponedilok.co</a> для забезпечення своїх прав, відповідно до умов цієї Політики Конфіденційності, або у разі порушення його прав, або залишити відгук або поставити запитання.</p>
  </div>
</div>
