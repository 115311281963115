import {Component, OnInit} from '@angular/core';
import {MatSnackBarRef} from '@angular/material/snack-bar';

import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  constructor(private snackBarRef: MatSnackBarRef<CookieBannerComponent>) {}

  ngOnInit(): void {
  }

  acceptCookie(): void {
    this.snackBarRef.dismissWithAction();
  }
}
