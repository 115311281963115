import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ProfileService} from '@profile/profile.service';
import {Doctor} from '@core/interfaces/doctor/doctor.interface';
import {WizardStepsEnum} from '@core/enums/wizard-steps.enum';


@Injectable({
  providedIn: 'root'
})
export class AppGuard {
  constructor(
    private router: Router,
    private profileService: ProfileService
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.profileService.isAuthenticated()) {
      if (!this.profileService.profile$.value) {
        return this.profileService.loadProfile().pipe(
          map((profile: Doctor) => {
            if (
              profile.step < WizardStepsEnum.PREVIEW &&
              !localStorage.getItem('wizard')
            ) {

              this.router.navigate(['/wizard']);
            }
            return true;
          })
        );
      }

      return true;
    }

    this.router.navigate(['/auth/login']);
    return false;
  }
}
