import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {getObjectUrlParams} from '../utils/filter-converter';
import {environment} from '../../../environments/environment';
import {
  GetSpecializationsParams
} from '../interfaces/specialization/specialization-params.interface';
import {Specialization} from '../interfaces/specialization/specialization.interface';
import {JsonaService} from '@core/services/jsona.service';

@Injectable()
export class SpecializationService {
  private apiUrl = `${environment.apiUrlV2}/specializations`;

  constructor(
    private http: HttpClient,
    private jsonaService: JsonaService
  ) {
  }

  getSpecializations(filter: GetSpecializationsParams): Observable<Specialization[]> {
    const params = getObjectUrlParams(filter);

    return this.http.get(this.apiUrl, {params})
      .pipe(
        map(res => this.jsonaService.deserialize<any>(res)?.data)
      );
  }
}
