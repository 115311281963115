<div class="page-container" *ngIf="(patient$ | async) as patient">
  <div class="avatar" [class.avatar-exist]="patient.avatar">
    <ng-container *ngIf="patient.avatar; else image">
      <app-safe-image
        [name]="patient.avatar"
        [storage]="patient.avatar_storage"
      ></app-safe-image>
    </ng-container>

    <ng-template #image>
      <img src="/assets/images/default-avatar.svg" alt="avatar client"/>
    </ng-template>
  </div>

  <p class="title" *ngIf="!data.isReschedule; else reschedule">
    {{'Пригласить' | translate}} {{'на консультацию' | translate}}
  </p>

  <ng-template #reschedule>
    <p class="title">
      {{'Перенести консультацию' | translate}}
    </p>
  </ng-template>

  <span class="patient-name">{{patient.name}}</span>

  <app-doctor-work-points
    [patientId]="patientId"
    (close)="modal.close(false)">
  </app-doctor-work-points>
</div>
