import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {buildFormData} from '@core/utils/build-form-data';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {JsonaService} from '@core/services/jsona.service';
import {
  ConfirmOrderChangeStatusComponent
} from '../components/confirm-order-change-status/confirm-order-change-status.component';
import {OrderStatusesEnum} from '../enums/order-statuses.enum';
import {OrderModel} from '../models/order.model';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private readonly url = `${environment.apiUrl}/order-services`;

  pendingOrdersCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient,
    private jsona: JsonaService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
  }

  getOrders(params: any = {}) {
    const p: any = {
      ...params,
      createAtFrom: params.createAtFrom ? params.createAtFrom.unix() : '',
      createAtTo: params.createAtTo ? params.createAtTo.unix() : ''
    };

    if (typeof p.status === 'number') {
      p.statuses = [p.status];
    }

    delete p.status;

    const query = this.jsona.makeQueryString(p);

    return firstValueFrom(
      this.http
        .get(`${this.url}?${query}`)
        .pipe(
          map(res => this.jsona.deserialize<OrderModel[]>(res))
        )
    );
  }

  getOrderById(id: number): Promise<OrderModel> {
    return firstValueFrom(
      this.http.get(`${this.url}/${id}`, {params: {relation_file: 1}})
        .pipe(
          map(res => this.jsona.deserialize<OrderModel>(res).data)
        )
    )
  }

  rejectOrder(orderId: number) {
    return this.http.delete(`${this.url}/${orderId}`);
  }

  changeStatus(orderId: number, data) {
    const formData = buildFormData(data);
    return this.http.post(`${this.url}/change-status/${orderId}`, formData);
  }

  confirm(order: OrderModel, toStatus: OrderStatusesEnum) {
    return this.dialog
      .open(ConfirmOrderChangeStatusComponent, {
        autoFocus: false,
        panelClass: ['primary-modal', 'modal-sm', 'order-confirm'],
        data: {
          order,
          toStatus
        }
      })
      .afterClosed();
  }

  createResultForm() {
    return this.fb.group({
      result: ['', [Validators.required, Validators.maxLength(10000)]],
      files: [[]]
    });
  }
}
