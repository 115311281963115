<ng-container *ngIf="name && (imageUrl$ | async) as imageUrl; else defaultImage">
  <img
    [class.cover]="cover"
    [src]="imageUrl" alt="Ponedilok"
  />
</ng-container>

<ng-template #defaultImage>
  <div class="empty_avatar">
    <img
      [class.cover]="cover"
      [src]="'/assets/images/' + default"
      alt="Ponedilok"
    />
  </div>
</ng-template>
