import {Component, DestroyRef} from '@angular/core';
import {Observable} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

import {MessagesCountsService} from '@core/services/messages-counts.service';

@Component({
  selector: 'app-link-dialogues',
  templateUrl: './link-dialogues.component.html',
  styleUrls: ['./link-dialogues.component.scss']
})
export class LinkDialoguesComponent {
  unreadMessages$: Observable<number> = this.messagesCountsService.unreadMessagesCount$;

  constructor(
    private destroyRef: DestroyRef,
    private messagesCountsService: MessagesCountsService
  ) {
    this.getUnreadMessages();
  }

  private getUnreadMessages(): void {
    this.messagesCountsService.getUnreadMessages()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
