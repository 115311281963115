import { Component } from '@angular/core';
import {LogoComponent} from '@shared-modules/layouts/app-layout/components/logo/logo.component';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {

}
