import {NgIf} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {SafeImageModule} from '@shared-modules/safe-image/safe-image.module';
import {OrderStatusesEnum} from '../../enums/order-statuses.enum';
import {OrderModel} from '../../models/order.model';

@Component({
  selector: 'app-confirm-order-change-status',
  standalone: true,
  imports: [
    NgIf,
    SafeImageModule,
    TranslateModule,
    MatDialogClose
  ],
  templateUrl: './confirm-order-change-status.component.html',
  styleUrl: './confirm-order-change-status.component.scss'
})
export class ConfirmOrderChangeStatusComponent implements OnInit {
  order: OrderModel;
  toStatus: OrderStatusesEnum;

  protected readonly orderStatus = OrderStatusesEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {
      order: OrderModel;
      toStatus: OrderStatusesEnum;
    }
  ) {
  }

  ngOnInit() {
    this.order = this.data.order;
    this.toStatus = this.data.toStatus;
  }
}
