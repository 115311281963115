import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';

import {ModalInfoComponent} from './modal-info.component';
import {ModalHeaderModule} from '../modal-header/modal-header.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';


@NgModule({
  declarations: [
    ModalInfoComponent
  ],
  imports: [
    CommonModule,
    ModalHeaderModule,
    MatDialogModule,
    TranslateModule,
  ],
  exports: [
    ModalInfoComponent
  ]
})
export class ModalInfoModule {
}
