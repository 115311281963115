import {Component, DestroyRef, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {filter} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

import {NetworkStateTypesEnum} from '@shared-modules/network-state/network-state-types.enum';
import {NetworkStateService} from '@shared-modules/network-state/network-state.service';
import {CentrifugoSocketService} from '@core/services/centrifugo-socket.service';

@Component({
  selector: 'app-my-network-error',
  templateUrl: './my-network-error.component.html',
  styleUrls: ['./my-network-error.component.scss']
})
export class MyNetworkErrorComponent implements OnInit {
  chatId: string;
  isActiveReconnectBtn = window.navigator.onLine;

  constructor(
    private destroyRef: DestroyRef,
    private socketService: CentrifugoSocketService,
    private networkStateService: NetworkStateService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.handleOnNetworkAndSocketConnection();
    this.handleRouteSubject();
  }

  private handleRouteSubject(): void {
    this.route.params
      .pipe(
        filter((params) => params?.id),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((params: Params) => (this.chatId = params.id));
  }

  private handleOnNetworkAndSocketConnection(): void {
    combineLatest([
      this.socketService.socketConnectionSuccess$.pipe(filter((c: any) => c)),
      this.networkStateService.networkConnectionState$.pipe(filter((event: string | null) => !!event))
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([socket, network]) => {
        this.isActiveReconnectBtn = socket && network === NetworkStateTypesEnum.Online;
      });
  }
}
