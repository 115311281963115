import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {DoctorSearchParamsInterface} from './doctor-search-params.interface';
import {ApiDoctorSearchInterface, DoctorSearchInterface} from './doctor-search.interface';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {ApiListResponse} from '@core/interfaces/list-response.interface';

@Injectable()
export class DoctorSearchService {
  private apiUrl = `${environment.apiUrl}/doctors/context`;

  constructor(private http: HttpClient) {
  }

  doctorSearch(filter: DoctorSearchParamsInterface): Observable<DoctorSearchInterface[]> {
    const params = getObjectUrlParams(filter);

    return this.http
      .get<ApiListResponse<ApiDoctorSearchInterface>>(this.apiUrl, {params})
      .pipe(
        map(
          (result: ApiListResponse<ApiDoctorSearchInterface>) => {
            return result.data.map((doctor: ApiDoctorSearchInterface) => {
              return {
                ...doctor.attributes,
                id: doctor.id
              };
            });
          })
      );
  }
}
