<a
  *ngIf="profile$ | async as profile"
  [class.not-user-select]="isChatPage"
  class="profile"
  [matMenuTriggerFor]="menu"
>
  <div class="profile_avatar">
    <app-safe-image
      [name]="profile.avatar_original"
      [storage]="profile.avatar_storage"
    ></app-safe-image>
  </div>
</a>

<mat-menu #menu="matMenu" class="header-menu">
  <button mat-menu-item routerLink="/profile">
    <mat-icon class="material-symbols-outlined">person</mat-icon>
    {{'Мій профіль' | translate}}
  </button>

  <button mat-menu-item routerLink="/profile-settings">
    <mat-icon class="material-symbols-outlined">settings</mat-icon>
    {{'Настройки' | translate}}
  </button>
</mat-menu>
