<a [routerLink]="['/dialogues']" routerLinkActive="active" class="dialogs-link">
  <mat-icon fontSet="material-icons-outlined">mail</mat-icon>

  <span
    class="badge"
    [matBadgePosition]="'below after'"
    [matBadge]="unreadMessages$ | async"
    [matBadgeHidden]="(unreadMessages$ | async) === 0"
  ></span>
</a>
