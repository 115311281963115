import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {PatientsService} from '../patients/patients.service';
import {Patient} from '@core/interfaces/patient.interface';
import {ModalInfoService} from '@core/services/modal-info.service';

@Component({
  selector: 'app-patients-invite',
  templateUrl: './patients-invite.component.html',
  styleUrls: ['./patients-invite.component.scss']
})
export class PatientsInviteComponent implements OnInit {
  patientId!: number;
  patient$: Observable<Patient>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public modal: MatDialogRef<PatientsInviteComponent>,
    private patientsService: PatientsService,
    private modalInfoService: ModalInfoService,
  ) {
  }

  ngOnInit(): void {
    this.patientId = Number(this.data.patientId);
    this.patient$ = this.patientsService.byId(this.patientId, {})
      .pipe(
        catchError(err => {
          this.modal.close();
          this.modalInfoService.onError(err);
          return of(null);
        })
      );
  }
}
