import { Component } from '@angular/core';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {TranslateModule} from '@core/pipes/translate/translate.module';

@Component({
  selector: 'app-link-orders',
  standalone: true,
  imports: [
    RouterLinkActive,
    TranslateModule,
    RouterLink
  ],
  templateUrl: './link-orders.component.html',
  styleUrl: './link-orders.component.scss'
})
export class LinkOrdersComponent {

}
