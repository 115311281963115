import {Component, Input} from '@angular/core';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {MatIcon} from '@angular/material/icon';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-empty-header',
  standalone: true,
  imports: [
    TranslateModule,
    MatIcon
  ],
  templateUrl: './empty-header.component.html',
  styleUrl: './empty-header.component.scss'
})
export class EmptyHeaderComponent {
  @Input() title: string = '';

  constructor(
    private location: Location,
    private dialog: MatDialog
    ) {

  }

  back(): void {
    this.dialog.closeAll();
    this.location.back()
  }
}
