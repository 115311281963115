import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {getObjectUrlParams, getStringUrlParams} from '@core/utils/filter-converter';
import {
  ApiConsultationCountByDays,
  Consultation,
  ConsultationCountByDays
} from './interfaces/consultation.interface';
import {
  CreateConsultationFilter,
  CreateConsultationResultFilter,
  GetConsultationByIdFilter,
  GetConsultationsCountByDaysFilter, updateConsultationFilter
} from './interfaces/consultations-filter.interface';
import {
  ApiConsultationCreateResponse,
} from './interfaces/consultations-response.interface';
import {ApiResponse} from '@core/interfaces/response.interface';
import {ConsultationsListFilter} from '@consultations/interfaces/consultations-list-filter.interface';
import {prepareConsultation} from '@core/utils/prepare-entity/prepare-consultation';
import {JsonaService} from '@core/services/jsona.service';
import {DeserializedJsonInterface} from '@core/interfaces/jsona.interface';

@Injectable()
export class ConsultationsService {
  rescheduleConsultationId$ = new BehaviorSubject<number | null>(null);
  selectedConsultation$ = new BehaviorSubject<Consultation | null>(null);

  private apiUrl = `${environment.apiUrl}/consultations`;
  private apiUrlV2 = `${environment.apiUrlV2}/consultations`;

  constructor(
    private http: HttpClient,
    private jsonaService: JsonaService
  ) {
  }

  createConsultation(filter: CreateConsultationFilter): Observable<Consultation> {
    const params = getObjectUrlParams(filter);

    return this.http
      .post<ApiConsultationCreateResponse>(this.apiUrl, params)
      .pipe(
        map((consultation: ApiConsultationCreateResponse) => {
          return prepareConsultation(consultation.data);
        })
      );
  }

  getConsultationsByDays(
    filter: Partial<GetConsultationsCountByDaysFilter>
  ): Observable<ConsultationCountByDays[]> {
    const params = getStringUrlParams(filter);

    return this.http
      .get<ApiResponse<ApiConsultationCountByDays[]>>(`${this.apiUrl}?${params}`)
      .pipe(
        map((result: ApiResponse<ApiConsultationCountByDays[]>) => {
          return result.data.map((consultation) => {
            return {
              id: consultation.id,
              ...consultation.attributes
            };
          });
        })
      );
  }

  getConsultationsList(filter: ConsultationsListFilter): Observable<DeserializedJsonInterface<Consultation[]>> {
    const params = getStringUrlParams(filter);

    return this.http
      .get(`${this.apiUrlV2}?${params}`)
      .pipe(
        map((result) => this.jsonaService.deserialize(result))
      );
  }

  getConsultationById(
    id: number,
    filter: GetConsultationByIdFilter
  ): Observable<DeserializedJsonInterface<Consultation>> {
    const params = getStringUrlParams(filter);

    return this.http
      .get(`${this.apiUrlV2}/${id}?${params}`)
      .pipe(
        map(result => this.jsonaService.deserialize(result))
      );
  }

  updateConsultation(filter: Partial<updateConsultationFilter>, id: number): Observable<void> {
    return this.http.patch<void>(`${this.apiUrlV2}/${id}`, filter);
  }

  cancelConsultation(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  changeConsultationStatus(id: number, statusId: number): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/change-status/${id}/${statusId}`);
  }
}
