import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {
  ApiUnreadMessagesInterface
} from '@shared-modules/layouts/app-layout/interfaces/sidebar-counters.interface';


@Injectable({
  providedIn: 'root'
})
export class MessagesCountsService {
  unreadMessagesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private apiUrl = `${environment.apiUrl}/discussion-message`;
  constructor(private http: HttpClient) { }

  getUnreadMessages(): Observable<void> {
    return this.http
      .get<ApiUnreadMessagesInterface>(`${this.apiUrl}/count`)
      .pipe(
        map((result: ApiUnreadMessagesInterface) => this.unreadMessagesCount$.next(result.count_not_read_message))
      );
  }

  readAllMessages(id: number): Observable<void> {
    return this.http
      .get(`${this.apiUrl}/read-all?discussion_id=${id}`)
      .pipe(
        map((res: ApiUnreadMessagesInterface) => this.unreadMessagesCount$.next(res.count_not_read_message)),
      )
  }
}
