<div class="empty card" [class.schedule]="type === 'schedule'">
  <div class="empty_wrap">
    <span class="title">{{ title | translate }}</span>

    <div
      class="empty_img"
      *ngIf="!withoutImg"
    >
      <img src="assets/images/empty_consultation.svg" alt="empty">
    </div>

    <div class="empty_action" *ngIf="type !== 'base'">
      <button
        *ngIf="type === consultationsStatusesNamesEnum.planned"
        class="btn btn-primary"
        (click)="invitePatient()">
        {{ 'Пригласить клиента' | translate }}
      </button>

      <a
        *ngIf="type === 'schedule'"
        routerLink="/schedule/schedule-board"
        class="btn btn-primary"
      >
        {{ 'Создать расписание' | translate }}
      </a>
    </div>
  </div>
</div>
