import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GotoDialogComponent } from './goto-dialog.component';
import {MatIcon} from '@angular/material/icon';



@NgModule({
  declarations: [
    GotoDialogComponent
  ],
  exports: [
    GotoDialogComponent
  ],
    imports: [
        CommonModule,
        MatIcon
    ]
})
export class GotoDialogModule { }
