import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {GetWaitingAreaListParamsInterface} from '../interfaces/waiting-area-params.interface';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {WaitingAreaInterface} from '../interfaces/waiting-area.interface';
import {ModalInfoComponent} from '@shared-modules/modals/modal-info/modal-info.component';
import {ApiConsultation, Consultation} from '@consultations/interfaces/consultation.interface';
import {ApiResponse} from '@core/interfaces/response.interface';
import {prepareConsultation} from '@core/utils/prepare-entity/prepare-consultation';
import {JsonaService} from '@core/services/jsona.service';
import {DeserializedJsonInterface} from '@core/interfaces/jsona.interface';

@Injectable({providedIn: 'root'})

export class WaitingAreaService {
  onWaitingAreaUpdate$: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  currentQuickConsultationId$: BehaviorSubject<number> = new BehaviorSubject(null);
  newQuickConsultation$: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  private apiUrl = `${environment.apiUrl}/waiting-aria`;
  private apiUrlV2 = `${environment.apiUrlV2}/waiting-aria`;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private jsonaService: JsonaService
  ) {
  }

  enterWaitingArea(): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/line-up`);
  }

  exitWaitingArea(): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/exit`);
  }

  getWaitingAreaList(
    filter: Partial<GetWaitingAreaListParamsInterface>
  ): Observable<DeserializedJsonInterface<WaitingAreaInterface[]>> {
    const params = getObjectUrlParams(filter);
    return this.http.get(this.apiUrlV2, {params})
      .pipe(map((result) => (
        this.jsonaService.deserialize<WaitingAreaInterface[]>(result)
      )));
  }

  takePatient(waitingAreaId: number): Observable<Consultation> {
    return this.http
      .get<ApiResponse<ApiConsultation>>(`${this.apiUrl}/take-patient/${waitingAreaId}`)
      .pipe(
        map((result: ApiResponse<ApiConsultation>) => {
          return prepareConsultation(result.data);
        })
      );
  }

  onConsultationNotAvailable(): void {
    this.dialog.open(ModalInfoComponent, {
      data: {
        title: 'Заявка не актуальна',
        message: 'К сожалению, другой врач уже принял эту заявку',
        btnText: 'Ок'
      },
      panelClass: ['primary-modal'],
      autoFocus: false
    });
  }
}
