import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {NetworkStateTypesEnum} from './network-state-types.enum';

@Injectable({
  providedIn: 'root'
})
export class NetworkStateService {
  networkConnectionState$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  isMobileDevice$ = new BehaviorSubject<boolean | null>(null);

  checkNetworkState(): void {
    window.addEventListener(NetworkStateTypesEnum.Online, (e) => {
      this.networkConnectionState$.next(e.type);
    });

    window.addEventListener(NetworkStateTypesEnum.Offline, (e) => {
      this.networkConnectionState$.next(e.type);
    });
  }

  checkBrowser(): void {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    this.isMobileDevice$.next(regex.test(navigator.userAgent));
  }
}
