import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {InviteRequest} from '../interfaces/invite-request.interface';
import {ApiDoctor, Doctor} from '@core/interfaces/doctor/doctor.interface';
import {GetDoctorByIdParamsInterface} from '@core/interfaces/doctor/doctor-params.interface';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {JsonaService} from '@core/services/jsona.service';

@Injectable()
export class DoctorsService {
  private apiUrl = `${environment.apiUrl}/doctors`;
  private apiUrlV2 = `${environment.apiUrlV2}/doctors`;

  constructor(
    protected http: HttpClient,
    private jsonaService: JsonaService
  ) {
  }

  invite(data: InviteRequest): Observable<ApiDoctor> {
    return this.http.post<ApiDoctor>(`${this.apiUrl}/invite`, data);
  }

  byId(id: number, filter: Partial<GetDoctorByIdParamsInterface>): Observable<Doctor> {
    const params = getObjectUrlParams(filter);

    return this.http.get(`${this.apiUrlV2}/${id}`, {params})
      .pipe(
        map((result) => this.jsonaService.deserialize<Doctor>(result).data),
      );
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrlV2}/${id}`);
  }
}
