import {Component, DestroyRef, Input} from '@angular/core';
import {filter} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

import {ConsultationStatusesNamesEnum} from '@consultations/enums/consultation-statuses.enum';
import {
  ModalFindUserComponent
} from '@shared-modules/modals/modal-find-user/modal-find-user.component';
import {Patient} from '@core/interfaces/patient.interface';
import {PatientsInviteComponent} from '../../dashboard/patients-invite/patients-invite.component';

@Component({
  selector: 'app-empty-result',
  templateUrl: './empty-result.component.html',
  styleUrls: ['./empty-result.component.scss']
})
export class EmptyResultComponent {
  @Input() title = 'По данному запросу ничего не найдено!';
  @Input() type = 'base';
  @Input() withoutImg = false;

  consultationsStatusesNamesEnum = ConsultationStatusesNamesEnum;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {
  }

  invitePatient(): void {
    const dialogRef = this.dialog.open(ModalFindUserComponent, {
      data: {
        isDialogueModal: false,
        isHomePage: true
      },
      autoFocus: false,
      panelClass: ['primary-modal', 'modal-md']
    });

    dialogRef.afterClosed()
      .pipe(
        filter(patient => !!patient),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((patient: Patient) => {
        this.dialog.open(PatientsInviteComponent, {
          data: {
            patientId: patient.id
          },
          panelClass: ['primary-modal', 'modal-sm'],
          autoFocus: false
        });
      });
  }
}
