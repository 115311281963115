import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JsonaService} from './jsona.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {getObjectUrlParams} from '@core/utils/filter-converter';


@Injectable({
  providedIn: 'root'
})
export class ClinicSettingsService {
  clinicSettings$ = new BehaviorSubject(null);

  private apiUrl = `${environment.apiUrl}/settings`;

  constructor(
    private http: HttpClient,
    private jsonaService: JsonaService
  ) {}

  getList(filter): Observable<any> {
    const params = getObjectUrlParams(filter);

    return this.http.get(this.apiUrl, {params})
      .pipe(
        map(res => this.jsonaService.deserialize(res).data)
      );
  }
}
