export enum FileTypesEnum {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  APNG = 'image/apng',
  GIF = 'image/gif'
}

export enum FileExtensionsEnum {
  PDF = 'pdf',
  JPG = 'jpg',
  JPEG = 'jpeg',
  GIF = 'gif',
  PNG = 'png',
  APNG = 'apng',
  DOC = 'doc',
  DOCX = 'docx',
}
