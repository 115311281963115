import {ModalImagesEnum} from '../enums/modal-images.enum';

export const ModalImagesConst = [
  {
    type: ModalImagesEnum.base,
    image: '/assets/images/success-img.svg'
  },
  {
    type: ModalImagesEnum.attention,
    image: '/assets/images/attention.svg'
  },
  {
    type: ModalImagesEnum.confirm,
    image: '/assets/images/confirm.svg'
  },
  {
    type: ModalImagesEnum.recommendation,
    image: '/assets/images/recommendation.svg'
  },
  {
    type: ModalImagesEnum.invite,
    image: '/assets/images/success-img.svg'
  },
  {
    type: ModalImagesEnum.mail,
    image: '/assets/images/mail.svg'
  }
];
