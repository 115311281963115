import {Component, DestroyRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {filter} from 'rxjs/operators';

import {WindowSizeConfigInterface} from '@core/interfaces/window-size-config.interface';
import {WindowSizeService} from '@core/services/window-size.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() isHeaderShortened: boolean;

  @Output() toggleSidenav: EventEmitter<null> = new EventEmitter();

  windowSizeConfig: WindowSizeConfigInterface;

  showIcons = true;
  showLogo = false;

  constructor(
    private destroyRef: DestroyRef,
    private router: Router,
    private windowSizeService: WindowSizeService
  ) {
    this.handleWindowSizeConfig();
  }

  ngOnInit(): void {
    this.showIcons = !this.router.url.includes('wizard');
    this.showLogo = this.router.url.includes('chat') || !this.showIcons;

    this.watchRouterEvents();
  }

  private handleWindowSizeConfig(): void {
    this.windowSizeService.windowSizeConfig$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((windowSizeConfig: WindowSizeConfigInterface) => {
        this.windowSizeConfig = windowSizeConfig;
      });
  }

  private watchRouterEvents(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((event: NavigationEnd) => {

      this.showIcons = !event?.urlAfterRedirects.includes('wizard')
      this.showLogo = event?.urlAfterRedirects.includes('chat') || !this.showIcons;
    });
  }
}
