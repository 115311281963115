<div class="cookie-wrapper">
  <div class="cookie-icon">
    🍪
  </div>

  <div class="cookie-text">
    {{'cookie-text' | translate}}
    <a href="/privacy-policy" target="_blank">
      {{'Політику конфіденційності' | translate}}.
    </a>
  </div>

  <button class="btn btn-primary" type="button"
          (click)="acceptCookie()">
    {{ 'Погоджуюсь' | translate }}
  </button>
</div>

