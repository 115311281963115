export const environment = {
  production: false,
  apiUrl: 'https://ponedilok-api.bekey.io',
  apiUrlV2: 'https://ponedilok-api.bekey.io/v2',
  clientUrl: 'https://ponedilok-client.bekey.io',
  GOOGLE_AUTH_KEY: '978943384804-o4f16neka4gfj6r92ctrh5qeb4sr6mrb.apps.googleusercontent.com',
  FB_KEY: '1445098066141579',
  socketApi: 'wss://ponedilok-wss.bekey.io/connection/websocket',
  siteKey: '6LdhWKApAAAAABK-90-mfrNq0oI6xtQRNKbtr5L_',

  // posthog env, only production
  POSTHOG_KEY: 'phc_XjmJd9ZE0wvOJ9wQmbMCOBKMagiN7yPB4Rlw1Z8Kzum',
  POSTHOG_HOST: 'https://us.i.posthog.com',

  loggerConfig: {
    // socket
    socketInfo: true,
    socketError: true,
    // mediaChat
    mediaChatInfo: true,
    mediaChatError: true,
    // notification
    notificationInfo: true,
    // waitingArea
    waitingAreaInfo: true,
    // onlineChat
    onlineChatInfo: true,
    // dialogueChat
    dialogueInfo: true,
    // sidebar
    sidebarInfo: true,
  }
};
